.logo{
  cursor: pointer;
  background: url(./assets/logo.svg) no-repeat;
  background-size: 100% 100%;
  width: 32px;
  height: 32px;
  position: absolute;
  right:48px;
  top:7px;

}
.wrapLog {
  .ant-popover-arrow{
    display: none;
  }
}

.noData{
  padding-top:20px ;
  color: #7B7B7B;
}