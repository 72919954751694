:global {
  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: #35a200;
    background-color: #f6fff2;
    > .ant-steps-icon {
      color: #35a200;
    }
  }
  .ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
    margin-right: 5px;
  }
}
.tips {
  font-size: 14px;
  font-weight: 400;
  color: #8C8C8C;
  margin-bottom: 20px;
  .tipBtn {
    padding-left: 0;
  }
  .title {
    font-size: 16px;
    font-weight: 700;
    color: #595959;
  }
}

.item {
  //width: 232px;
  width: 100%;
  margin-bottom: 27px;
  user-select:none;

  .content {
    display: flex;
    margin-left: 10px;
    //width: 222px;
    border-radius: 4px;
    background-color: #F1F1F1;
    border: solid 1px #F1F1F1;
    padding: 5px 10px;
    align-items: center;

    .head {
      flex: 1;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      .title {
        color: #222222;
      }
      .company {
        margin-top: 5px;
        color: #707070;
      }
    }
    .bade {
      line-height: 14px;
      padding: 0 3px;
      height: 14px;
      font-size: 12px;
      border-radius: 4px;
      background-color: rgb(168, 195, 2);
      color: white;
      float: right;
    }


    &:before {
      content: '';
      position: absolute;
      top: 10px;
      left: 0px;
      height: 0;
      width: 0;

      border-top: 5px solid transparent;
      border-left: 5px solid transparent;
      border-right: 5px solid #F1F1F1;
      border-bottom: 5px solid transparent;
    }
  }
  .fields {
    margin: -5px 0 0 10px;
    //width: 222px;
    border-radius: 4px;
    background-color: #F1F1F1;
    border-bottom: solid 1px #F1F1F1;
    padding: 5px;

    font-size: 14px;
    font-weight: 400;
    color: #222222;

    .field {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 5px;
      line-height: 35px;
      background-color: white;
      .icon {
        font-size: 16px;
        color: rgba(194, 194, 194, 1);
      }
      .iconSelected {
        color: rgba(168, 195, 2, 1);
        font-size: 16px;
      }
      &:hover {
        background-color: rgba(241,241,241, 0.5);
      }
    }
  }

  .contentCurrent {
    background-color: #fff1e1;
    border-color: #fff1e1;
    &:before {
      border-right: 5px solid #fff1e1;
    }
  }

  .fieldCurrent {
    background-color: #fff1e1;
  }

}