:global {
  body[key='plugin-container'] {
    @primary-color: #A236B2;
    @component-background-color: #fff7ff;
    .ant-btn-primary[disabled], .ant-btn-primary[disabled]:active, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:hover {
      opacity: 35%;
      background-color: @primary-color;
      color: #fff;
    }
    .ant-calendar-picker-container {
      .ant-calendar {
        border: ~'--ant-calendar-border-style';
      }
    }

    .ant-tooltip .ant-tooltip-inner {
      color: rgba(0, 0, 0, 0.85);
      background-color: #fff;
      padding: ~'--ant-tooltip-padding';
      width: auto;
    }
    .ant-tooltip-arrow-content {
      --antd-arrow-background-color: #fff;
    }
    .ant-pagination-item-active {
      border-color: @primary-color;
      a {
        color: @primary-color;
      }
    }
    .ant-pagination-item-active:focus, .ant-pagination-item-active:hover {
      border-color: #f2d0f2;
      a {
        color: #f2d0f2;
      }
    }

    .SharedModel .contentBox p {
    }
    .open-file-wrapper {
      background-color:  @component-background-color;
    }
    .ant-calendar-picker-container {
      background-color:  @component-background-color;
    }
    .SharedBulkOperation {
      background-color:  @component-background-color;
    }
    .ant-table-tbody > tr.ant-table-row-selected td {
      background-color:  @component-background-color;
    }
    .ant-table tfoot>tr>td, .ant-table tfoot>tr>th, .ant-table-tbody>tr:not(.ant-table-placeholder)>td, .ant-table-thead>tr>th {
      padding: 10px !important;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #A236B2;
      text-shadow: none;
      font-weight: 500;
    }
    .ant-tabs-tab:hover {
      color: #e48bea;
    }
    .ant-btn-compact-item.ant-btn-primary:not([disabled])+.ant-btn-compact-item.ant-btn-primary:not([disabled]):after {
      background-color: @primary-color;
    }
    .ant-popover {
      max-width: none !important;
    }
    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      background-color: #f5f5f5;
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: #FFF3FF;
    }
    .org-name-autocomplete {
      max-height: 130px;
    }
    tr.ant-table-measure-row{
      visibility: collapse;
    }
  }

}