body[class='phantom-container'] *:not([support-outline='true']) {
  outline: 0px solid rgba(255, 255, 255) !important;
}

.table-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.esign_table_dropdown {
  .ant-btn {
    &.ant-btn-primary:not([disabled]) {
      border-color: #e48bea !important;
      color: #e48bea !important;
      text-shadow: none !important;
    }
  }
  .ant-btn-primary:focus:not([disabled]), .ant-btn-primary:hover:not([disabled]), .ant-btn:focus:not([disabled]), .ant-btn:hover:not([disabled]), .ant-btn.core-btn:focus:not([disabled]), .ant-btn.core-btn:hover:not([disabled]) {
    background-color: #fcdafe;
    border-color: #e2a1e6;
    color: #e48bea;
  }
  .ant-btn-compact-item.ant-btn-primary:not([disabled]) + .ant-btn-compact-item.ant-btn-primary:not([disabled]):after {
    background-color: #e48bea !important;
  }
}

.round {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.textPortrait {
  .round();
  background: #B0B0B0;
  text-align: center;
  color: #fff;
  font-size: 12px;
}
.defaultPortrait{
  .textPortrait();
  margin: 0 auto;
  background: #9DB4E2;
  cursor: pointer;
}
BeyondHidden {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  justify-content: center;
  align-items: center;
}
cleanFloat {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}
// @mixin GrayColor {
popBox {
  .title {
    //@include GrayColor;
    font-size: 14px;
    text-align: left;
    border-bottom: 1px solid #e4e4e4;
    margin-bottom: 0;
    padding-bottom: 12px;
    //@include cleanFloat;
    color: #000000;
    .successMsg {
      float: right;
      color: #A236B2;
      font-size: 12px;
    }
  }
  .addUserBox {
    border-bottom: 1px solid #e4e4e4;
    .addBtnBox {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 50px;
      .addIcon {
        cursor: pointer;
        background: #A236B2;
        @include userPortrait;
        text-align: center;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      span {
        color: #333333;
        font-size: 12px;
        cursor: pointer;
        padding: 12px;
      }
    }
    .ant-spin-nested-loading > div > .ant-spin {
      position: absolute;
    }
    .addBox {
      padding: 10px 0 0 0;
      @include cleanFloat;
      .ant-select-selection__clear {
        display: none;
      }
      .ant-select-show-search {
        width: 100%;
      }
      .ant-select-selection__placeholder {
        padding-left: 26px;
      }
      .ant-select-search__field__wrap {
        .ant-input {
          border-radius: 50px;
          padding-left: 40px;
          //background: url('../../../assets/sidebar/search2.svg') no-repeat 12px 50%; todo
          background-size: 20px;
        }
      }
      .ant-legacy-form {
        .ant-legacy-form-item {
          margin-bottom: 0px !important;
        }
      }
      @include primary-button('.addBtn') {
        @include normal();
        float: right;
        margin: 15px 0;
      }
      // .addBtn{width: 72px;height: 26px;border-radius: 13px;background-color: #A236B2;color: #fff;float:right;margin: 15px 0;}
      .selectUserList {
        list-style: none;
        padding-left: 0;
        display: flex;
        justify-content: flex-start;
        margin: 0;
        flex-wrap: wrap;
        max-height: 70px;
        overflow-y: auto;
        overflow-x: hidden;
        //&::-webkit-scrollbar {width:5px;}
        //&::-webkit-scrollbar-thumb {background: #eee;}
        li {
          max-width: 180px;
          height: 24px;
          border-radius: 13px;
          border: 1px solid #e4e4e4;
          padding: 0px 26px 0px 8px;
          position: relative;
          @include BeyondHidden;
          margin: 8px 8px 0 0;
          span {
            position: absolute;
            right: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .headPortrait {
    .guest-avatar {
      //background: url('../../../assets/Collaboration/guest.svg') 0 0 / 100% 100% no-repeat; todo
    }
    .user-avatar {
      display: inline-block;
      width: 20px;
      height: 20px;
      border: 1px solid #fff;
      border-radius: 50px;
    }
    margin-right: 8px;
    span {
      @include userPortrait;
      background: #9db4e2;
      display: block;
      text-align: center;
      line-height: 17px;
      border: 1px solid #fff;
    }
    img {
      @include userPortrait;
      margin-left: 0;
      margin-right: 0;
    }
  }
  .content {
    width: 100%;
    p {
      width: 85%;
      margin-bottom: 0;
      font-size: 12px;
      //@include BeyondHidden;
      //@include GrayColor;
    }
    .identity {
      color: #333;
      font-size: 12px;
    }
    .identity-gray {
      color: #a5a5a5;
    }
    span {
      display: block;
      margin-top: -5px;
    }
  }
}
.EsignTable {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 5px;
  .statusSpan {
    cursor: pointer;
  }
  .statusIcon {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 10px;
    &.status_0 {
      background-color: #6480e8;
    }
    &.status_1 {
      background-color: #6480e8;
    }
    &.status_2 {
      background-color: #52c41a;
    }
    &.status_3 {
      background-color: rgba(217, 217, 217, 1);
    }
    &.status_4 {
      background-color: #BCC1D1;
    }
    &.status_5,&.status_6,&.status_7  {
      background-color: red;
    }

    &.status_6  {
      background-color: rgba(242, 136, 61, 1);
    }

    &.status_7  {
      background-color: rgba(227, 64, 54, 1);
    }



  }
  .statusTitle {
    display: flex;
    justify-content: space-between;
    span {
      font-size: 14px;
    }
    .currentSinger {
      margin-right: 10px;
    }
  }
  .signBtn {
    width: 80px;
  }
  .c797979 {
    color: #797979 !important;
  }
  .sharedPopoverBox {
    width: auto;
    .user {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 20px;
    }
    @include popBox();
    .content {
      width: 190px !important;
      p {
        width: 100%;
      }
    }
    .urgeBtn {
      color: #ffffff;
      height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #A236B2;
      border-radius: 4px;
      cursor: pointer;
    }
  }
  .ant-btn-group.ant-dropdown-button {
    display: flex;
  }
  //   .ant-popover{
  //     border: 1px solid #D8D8D8;
  //     box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25 );
  //     border-radius: 4px;
  //     background-color: #fff;
  //  }
  .tipIcon {
    margin-left: 6px;
    cursor: pointer;
  }
  .noArrow {
    &.ant-popover {
      border: 1px solid #d8d8d8;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
    }
    .ant-popover-arrow{
      display: none;
    }
    .ant-popover-inner{
      border: none;
      box-shadow: none;
    }
  }
  .wrapPopTitle {
    width: 254px;
  }
  .namePop {
    padding: 0;
    .ant-popover-inner-content {
      padding: 5px 10px;
    }
  }

}
.esigncn-delete-modal {
  display: flex;
  flex-direction: row;
  align-items: start;

  .icon {
    font-size: 24px;
    color: rgba(25, 144, 255, 1);
    margin-left: 30px;
  }

  .title {
    flex: 1;
    margin-left: 10px;
    margin-right: 30px;

    .red {
      font-size: 12px;
      color: red;
    }
  }
}
.esigncn-cancel-modal, .esigncn-refuse-modal {
  .title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .icon {
      font-size: 24px;
      color: rgba(251, 183, 52, 1);
      margin-right: 10px;
      width: 24px;
      height: 24px;
    }
  }
  .red {
    font-size: 12px;
    color: red;
    margin-top: 5px;
  }
}
