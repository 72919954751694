.pluginBackground {
  .skeletonWarp {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
  }
  .full() {
    width: 100vw;
    height: calc(100vh - 40px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999;
  }
  .full();
  background-color: #fff;
  .fullScreen {
    .full();
  }
  .pluginContainer {
    border: none;
  }
}
