.search-form {
  background-color: var(--content-bg-color);
  .more-search-panel {
    margin-top: 16px;
  }
  .search-row {
    display: flex;
    flex-wrap: nowrap;
    .search-form-item {
      width: 260px;
      margin-right: 10px;
      margin-bottom: 10px;
      &-large {
        width: 320px;
      }
      .ant-form-item-label {
        width: 70px;
        text-align: left;
      }
    }
  }
}