:global {
  body[data-env='common-sign-validator'] {
    ::selection {
      background-color: #b0d7fb;
      color: inherit;
    }
    .ant-image-preview-operations {
      padding-right: 26px;
    }
  }
}

.btn {
  background-color: #FF5F00;
  color: #fff;
  border-radius: 20px;
  margin: 18px 0 15px 0;
  &:not([disabled]):hover, &:not([disabled]):focus {
    background-color: #f37e36 !important;
    color: #fff !important;
    border-color: transparent !important;
  }
  &[disabled] {
    background-color: #c3c3c3 !important;
    color: #fff !important;
    border-color: transparent;

  }
}
.flexRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.subBtn {
  background-color: #fff;
  border-color: #000;
  border-width: 2px;
  border-radius: 20px;
  margin: 18px 0 15px 0;
  &:hover, &:focus {
    background-color: #fff !important;
    color: #000 !important;
    border-color: #000 !important;
  }
}

.container {
  line-height: 1.6;
  color: #333;
  .strong {
    font-weight: 700;
    color: #333;
  }
  .ulStyle {
    padding-left: 17px;
    color: #8B8B8B;
    font-size: 12px;
  }
  .link {
    color: #1677FF;
    // 下划线
    text-decoration: underline;
  }
  .error {
    color: #FF0000;
  }

  .banner {
    background-color: #F0F3F8;
    padding: 20px;
    .content {
      width: 640px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
    }
    .left {
      align-self: stretch;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .title {
        font-size: 24px;
        .strong();
      }
      .desc {
        width: 83%;
        margin: 18px 0;
      }

      .tips {
        .ulStyle();
      }
    }
    .right {
      width: 182px;
      height: 182px;
      background-image: url("../../../assets/images/phantom/sign_validator_bg1.svg");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      .flex {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }
  .main {
     .content {
       color: #8B8B8B;
       width: 640px;
       margin: 0 auto;
       padding: 15px 0;
       .title {
         .strong();
         font-size: 18px;
         margin: 10px 0;
       }
       .wrap {
         display: flex;
         margin-bottom: 5px;
         .left {
           width: 12px;
           position: relative;
           .cycle {
             position: absolute;
             left: 50%;
             transform: translateX(-50%);
             color: #fff;
             font-size: 10px;
             text-align: center;
             line-height: 16px;
             width: 16px;
             height: 16px;
             border-radius: 50%;
             background-color: #F68B1F;
           }
           .cycle1 {
             top: 2px;
             .cycle();
           }
           .cycle2 {
             bottom: 2px;
             .cycle();
           }
           &:before {
             display: inline-block;
             padding: 10px 0;
             content: '';
             width: 1px;
             border-left: 1px dashed #F68B1F;
             position: absolute;
             top: 2px;
             bottom: 2px;
             left: 50%;
           }
         }
         .right {
           margin-left: 15px;
           .subTitle {
             .strong();
             font-size: 14px;
           }
           .desc {
             .ulStyle();
           }
         }
       }
       .bg {
         margin-top: 10px;
         width: 100%;
         height: 300px;
         background-image: url("../../../assets/images/phantom/validator-bg.png");
         background-size: contain;
         background-repeat: no-repeat;
       }
     }
  }
}

.result {
  min-height: 100vh;
  width: 100vw;
  background-color: #F0F3F8;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;

  .formItem {
    display: flex;
    margin: 8px 0;
    .label {
      width: 100px;
      text-align: left;
    }
    .value {
      flex: 1;
    }
  }

  .main {
    width: 920px;
    padding: 12px 18px;
    background-color: #fff;
    font-size: 12px;
    .strong {
      font-weight: 700;
      color: #333;
    }
    .title1 {
      text-align: center;
      font-size: 24px;
      .strong();
    }
    .title {
      font-size: 16px;
      .strong();
    }
    .wrap {
      padding: 10px 0;
      display: flex;
      justify-content: space-between;
      .left {

      }
      .right {
        width: 260px;
        align-self: flex-start;
      }
    }
    .item {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      .left {

      }
      .right {
        width: 260px;
        align-self: flex-start;
        .previewWrap {
          width: 100px;
          height: 100px;
          border: 1px solid #00000026;
          border-radius: 8px;
          padding: 10px;
          margin-bottom: 12px;
        }
        .preview {
          width: 100%;
          height: 100%;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
        }
      }
    }
  }
}