.nameOptions {
  background-color: rgba(var(--border-color-rgb), 1);
  :global {
    .ant-select-item {
      padding: 5px 5px 0 5px;
    }

    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      background-color: rgba(var(--border-color-rgb), 1);
    }

    .rc-virtual-list-holder {
      max-height: 160px !important;
    }
  }

  padding: 0 0 5px 0;

  .item {
    color: var(--text-color);
    border: solid 1px rgba(var(--border-color-rgb), 0.65);
    border-radius: 2px;
    padding: 5px;
    margin: 0;
    background-color: var(--bg-color);

    &:hover {
      border-color: var(--primary-color);
    }

    .match {
      color: var(--primary-color);
    }

    .top {
      display: flex;
    }

    .elli {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: break-all;
    }

    .elli2 {
      white-space: unset;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      line-height: 24px;
    }

  }
}