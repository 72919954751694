@import '../../../../theme.css';

.container {
  :global {
    @primary-color: #982E91 !important;
    @component-background-color: #fff !important;
    .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
      background: @primary-color;
    }
    .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
      color: @primary-color;
    }
    .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
      color: @primary-color;
    }
    .ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:after {
      background-color: rgba(0, 0, 0, .16);
    }
    .ant-steps-item-process .ant-steps-item-icon {
      border-width: 0;
    }
    .ant-btn {
      border-radius: 4px;
      &.ant-btn-primary {
        border-color: @primary-color;
        background-color: @primary-color;
        color: @component-background-color;

      }
    }
  }
}

.step1 {
  width: 32px;
  height: 32px;
  display: flex;
    justify-content: center;
    align-items: center;
  background-color: #982E91;
  color: white;
  border-radius: 50%;
  font-size: 16px;
  border: 0;
}

.inputContainer {
  &:last-child {
    margin-top: 20px;
  }

  p {
    margin: 0 0 5px 0;
    padding: 0;
  }

  .error {
    color: #CD0404;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    margin-top: 10px;
    width: 100%;
  }
}

.doc {
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--content-bg-color-second);
  border-radius: 2px;
  align-items: center;
  line-height: 32px;
  background-color: var(--content-bg-color-second);
  color: var(--text-color);
  padding-left: 10px;
  margin-bottom: 20px;

  .btnLink {
    color: var(--link-text-color);
    border-width: 0;
    background-color: transparent;
    &:hover {
      background-color: transparent !important;
      border-color: transparent !important;
      color: var(--link-text-color-hover) !important;
    }
  }
}

.list {
  margin: 0;
  background-color: var(--content-bg-color-second);
  padding: 6px 0 2px 20px;

  li {
    margin-bottom: 10px;
    color: #982E91;
  }

  p {
    margin: 0;
    padding: 0;
    color: var(--text-color);
  }
}

.docPreview {
  height: 850px;
  overflow-y: auto;
}
.docPreviewPhantom {
  height: 60vh;
  overflow-y: auto;
}

.btn {
  background-color: #982E91 !important;
  border: unset !important;
  color: var(--text-color) !important;
  border-radius: 4px !important;

  &:hover {
    background-color: #982E91cc !important;
  }
}


