.main {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 380px;
  margin: 50px auto 0;
  .title {
    margin-top: 15px;
    font-weight: 700;
    font-size: 20px;
    color: #F58220;
  }
  .tips {
    margin: 25px 0 40px;
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    text-align: center;
  }
}
