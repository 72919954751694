@import 'onlineCssAdapter/onlineAdapter.less';
:global {
    button.ant-btn {
        border-radius: 4px ;
    }
}

.App {
    .skeleton {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 360px;
        margin: 100px auto 0;
    }
    overflow: hidden;
    background-color: var(--bg-color);
    color: var(--text-color);
}
