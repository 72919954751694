:global {
  .esign_nps .rate-btn {
    .ant-btn {
      padding: 0;
      width: 24px;
      height: 24px;
      text-align: center;
      line-height: 100%;
      box-shadow: none;
    }
  }
}

.wrap {
  background-color: #F5F6FA;
  position: relative;
  margin: 4px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  .close {
    position: absolute;
    right: 18px;
    top: 10px;
    cursor: pointer;
    z-index: 9999;
  }
  .container {
    position: relative;
    width: 90%;
    height: calc(100vh - 8px);
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    .header {
      width: 100%;
    }
    .title {
      width: 57%;
      font-size: 16px;
      font-weight: 700;
      text-align: center;
      margin-top: 10px;
      // 禁止选中
      user-select: none;
    }
    .main {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      gap: 10px;
      .ratingWrap {
        .ratingContainer {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 20px;
          gap: 16px;
          .rateBtn {
            background-color: red;
            transform: scale(1);
              &:hover {
                // 放大效果
                transform: scale(1.3);
                border: 1px solid #d9d9d9;
                color: #000000d9
              }
          }
          .active {
            color: #fff !important;
            border: none !important;
            box-shadow: none !important;
            transform: scale(1.2);
          }
        }
      }
      .indicatorBar {
        margin-top: 12px;
        height: 5px;
        width: 100%;
        background: linear-gradient(90deg, rgba(255, 80, 65, 0.4) 29.54%, rgba(253, 177, 29, 0.4) 70.25%, rgba(88, 199, 70, 0.4) 90.87%);
      }
      .flexRowJustify {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .flexRowCenter {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .feedbackForm {
        width: 388px;
        display: flex;
        flex-direction: column;
        // 高度过渡动画效果
        animation: npsHeightIn 0.3s;
        gap: 5px;
        @keyframes npsHeightIn {
          from {
            height: 0;
          }
          to {
            height: 103px;
          }
        }
      }
    }
    .submitBtn {
      width: 388px;
      margin-bottom: 18px;
    }

    .unhappyIcon {
      animation: emotionUnhappy 0.8s;
      @keyframes emotionUnhappy {
        from {
          transform: scale(1);
        }
        // 50%时缩小
        50% {
          transform: scale(1.3);
        }
        to {
          transform: scale(1);
        }
      }
    }
    .normalIcon {
      animation: emotionNormal 0.6s;
        @keyframes emotionNormal {
          from {
            transform: scaleY(1);
          }
          50% {
            transform: scaleY(0);
          }
          to {
            transform: scaleY(1);
          }
        }
    }
    .happyIcon {
      animation: emotionHappy 0.3s;
      @keyframes emotionHappy {
        from {
          transform: rotate(0deg);
        }
        // 50%时放大
        40% {
          transform: rotate(15deg);
        }
        80% {
          transform: rotate(-15deg);
        }
        100% {
          transform: rotate(0deg);
        }
      }
    }
  }

}

.result {
  position: relative;
  margin: 4px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  .close {
      position: absolute;
      right: 18px;
      top: 10px;
      cursor: pointer;
      z-index: 9999;
  }
  .resultWrap {
    height: calc(100vh - 16px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // 透明度实现过渡动画效果
    animation: npsFadeIn 0.8s;
    @keyframes npsFadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    .img {
      width: 120px;
      height: 120px;
      background-image: url("../../../assets/images/nps_result.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
