.main {
  display: flex;
  align-items: center;
  //flex-direction: column;
  width: 655px;
  padding: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .right {
    flex: 1;
    .title {
      margin-top: 15px;
      font-weight: 700;
      font-size: 20px;
      color: rgba(0, 0, 0, 0.85);
    }
    .tips {
      margin: 10px 0 20px;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      text-align: left;
    }
    .input_wrap {
      height: 58px;
      .error {
        color: #CD0404;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        text-align: left;
        margin-top: 5px;
        width: 100%;
      }
    }
    .buttons {
      width: 100%;
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
    }
  }
}

.sendCode{
  color: #0D86F5;
  cursor: default;
}
