.myDatePicker {
  margin-top: 20px;
  width: 218px;
  height: 281px;
  font-size: 14px;
  padding: 0 4px;
  user-select: none;
  display: none;
  background-color: white;

  z-index: 20;
  position: absolute;
  border: solid 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.2);

  .pickerHeader {
    display: flex;
    height: 35px;
    justify-content: space-between;

    div {
      height: 30px;
      text-align: center;
      line-height: 30px;
      font-weight: 600;
    }

    input {
      margin: 5px 0 0 5px;
      padding: 0;
      border: 0;
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      outline: 0;
      font-weight: 600;
      width: 55px
    }

    .select {
      margin: 5px 5px 0 0;
      cursor: default;
      &:hover {
        color: #982e91;
      }
    }
  }

  .pickerWeek {
    display: flex;
    div {
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 30px;
    }
    .weekend {
      color: #982e91;
    }
  }

  .pickerMonth {
    height: 240px;
    display: none;

    grid-template-columns: repeat(3,33%);   // 设置列
    grid-template-rows: repeat(4,25%);  // 设置行
    grid-column-gap: 0px; // 设置网格之间的间距
    grid-row-gap: 0px; // 设置网格之间的间距


    div {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;

      div {
        width: 60px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        cursor: default;
        &:hover {
          background-color: rgba(0,0,0,0.1);
          border-radius: 15px;
        }
      }


    }
  }

  .pickerList  {
    height: 180px;
    .day {
      display: flex;
      div {
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        cursor: default;
        &:hover {
          background-color: rgba(0,0,0,0.1);
          border-radius: 15px;
        }
      }

      .gray {
        color: rgba(0,0,0,0.5);
      }

      .selected {
        border-radius: 15px;
        color: white;
        background-color: #982e91;

        &:hover {
          color: white;
          background-color: #982e91;
        }
      }

    }
  }

  .pickerFooter {
    display: flex;
    justify-content: space-between;
    height: 30px;
    margin-top: 3px;
    border-top: 1px solid #d1d1d1;

    div {
      cursor: default;
      width: 40px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      color: #982e91;
    }
  }
}
