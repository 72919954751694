.table {
    margin-top: 20px;

}

:global {
    body[key='plugin-container'] {
        .auth_tip {
            .ant-tooltip-content {
                min-width: 255px;
            }
        }
        .ant-table-thead {
            background-color: rgba(0, 0, 0, 0.06);
        }
    }
}

.container {
    padding: 10px;
}