.main {
  display: flex;
  align-items: center;
  width: 100%;
  height: 46px;
  padding-left: 10px;
  box-shadow: 0px 2px 4px #E5E5E5;
  background-color: white;
  .name {
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    text-align: center;
    flex: 1;
  }
  .right {
    display: flex;
    justify-content: flex-end;
    width: 258px;
    .avatar {
      margin: 0 10px;
      font-size: 16px;
      color: #f56a00;
      background-color: #fde3cf;
      user-select: none;
    }
  }
}

.refuseContainer {
  .error {
    color: #CD0404;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    margin-top: 10px;
    width: 100%;
  }
}
