:global {
  .vc-switch {
    bottom: 153px !important;
  }
}
.container {
  height: 100vh;
  position: relative;
  padding: 5px 13px;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.primary-btn {
  background-color: #A236B2 !important;
  color: #fff !important;
  border-color: #fff !important;
}
.panelV2 {
  //display: flex;
  //flex-direction: column;
  //align-items: flex-start;
  .panel-title {
    font-weight: 500;
    margin-bottom: 5px;
    padding: 0 0 0 4px;
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .panel-title-left {
      display: flex;
      align-items: center;
    }
    .title-icon {
      border-radius: 8px;
      width: 16px;
      height: 16px;
      background-color: #A236B2;
      margin-right: 5px;
      display: inline-block;
      cursor: pointer;
      vertical-align: middle;
      &:hover {
        background-color: rgba(210, 133, 229, 0.8);
      }
    }
    .arrow-icon-down-white {
      background-position: center;
      background-image: url(../assets/arrow-down-white.svg);
      background-repeat: no-repeat;
      background-size: 9px 15px;
    }

    .arrow-icon-right-white {
      background-position: center;
      background-image: url(../assets/arrow-right-white.svg);
      background-repeat: no-repeat;
    }

  }
  .tipsList {
    padding-left: 20px;
    color: #8C8C8C;
  }
}
.flow-area {
  padding-left: 4px;
}

.guideMsg {
  color: #999;
  font-size: 12px;
  margin-bottom: 10px;
}

.receiptsItem {
  width: 100%;
  border-radius: 3px;
  margin-bottom: 10px;
  outline: 1px solid #ddd;
  .receiptsItemContent {
    display: flex;
    align-items: center;
    .left {
      flex: 1;
      display: flex;
      align-items: center;
      height: 60px;
      padding-right: 10px;
      position: relative;
      &:hover {
        background-color: #f2f2f2;
        .del-icon {
          display: inline-block;
        }
      }
      .dragIcon {
        background-image: url(../assets/drag.svg);
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 8px 32px;
        height: 32px;
        width: 8px;
        margin: 0 10px 0 5px;
      }
      .info-area {
        position: absolute;
        left: 20px;
        flex: 1;
        .primaryInfo {
          .annotCount {
            border-radius: 8px;
            color: #fff;
            display: inline-block;
            font-size: 12px;
            font-weight: 600;
            line-height: 12px;
            margin-right: 5px;
            min-width: 16px;
            padding: 2px;
            text-align: center;
          }
          .signerName {
            font-weight: 600;
          }
        }
        .secondInfo {
          color: #999;
          font-size: 12px;
        }
      }

    }
    .right {
      .rightArrow {
        cursor: pointer;
        background-image: url(../assets/arrow-right.svg);
        background-repeat: no-repeat;
        background-size: 6px 9px;
        background-position: center;
        display: block;
        height: 60px;
        width: 30px;
        transition:transform .3s;
      }
      .rotate90 {
        transform: rotate(90deg);
        transition:transform .3s;
      }
    }
  }
}
.btn-group {
  flex: 1;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  padding: 20px 0;
}
.drag {
  .drag-line {
    height: 1px;
    border-bottom: 2px dashed transparent;
    margin: 10px 0 0;
  }
}

.item-sortable-ghost {
  .drag-line {
    border-bottom-color: #000000;
  }
}

.gray-msg {
  color: #999;
  font-size: 12px;
  display: inline-block;
}
.signer-info-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 5px;
  .add-me {
    font-size: 14px;
    color: #A236B2;
    font-weight: 400 !important;
  }
  .signer-info-tip {
    background-color: #A236B2;
    border-radius: 8px;
    color: #fff;
    display: inline-block;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    width: 16px;
    vertical-align: 1px;
    margin-right: 5px;
  }
}
.annot-valid-error {
  color: #cd0404;
  font-size: 12px;
  margin-top: 8px;
}

.icon {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  height: 16px;
  width: 16px;
  display: inline-block;
}
.del-icon {
  background-image: url(../assets/del.svg);
  height: 30px;
  width: 20px;
  display: none;
  position: absolute;
  right: 20px;
  &:hover {
    cursor: pointer;
  }
}
.field-list-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.selected-field-list-container {
  border: 1px solid #d6d6d6;
  border-radius: 6px;
  margin: 10px 0 20px;
  .selected-field-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 30px;
    padding: 3px 10px;
    &:hover {
      background-color: #f2f2f2;
      .del-icon {
        display: inline-block;
      }
    }
  }
}

.field-detail-container {
  border-top: 1px solid #f2f2f2;
  margin: 0 20px;
  padding-bottom: 6px;
  .field-detail-item {
    padding-top: 5px;
    &:hover {
      background-color: #f2f2f2;
    }
  }
}

.guide-view-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #515151;
  line-height: 160%;
  position: absolute;
  top: 50%;
  transform: translateY(-60%);
  .guide-view-bg {
    width: 75vw;
    height: 40vh;
    background-image: url(../assets/guide_view_bg.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  h4 {
    font-weight: 600;
  }
  >p {
    margin: 10px 30px;
  }
}

.esign-auto-complete-container {
  .ant-select-dropdown {
    padding-top: 0;
    padding-bottom: 6px;
  }
  .ant-select-item {
    padding: 6px 6px 0 6px;
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #fff;
  }
}

.esign-auto-complete-options-container {
  background: #f9f9f9;
  border-radius: 2px;
  color: #444;
  cursor: default;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  padding: 10px;
  box-sizing: border-box;
  outline-offset: -1px;
  outline: 1px solid #eaeaea;
  &:hover {
    background: #fef8ff;
    outline: 1px solid #d285e5;
  }
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}
.light-height {
  color: #A236B2;
}

.in-sign-field-options-container {
  .esign-auto-complete-options-container();
  .name {
    .text-overflow();
  }
  .email {
    padding-top: 3px;
    .text-overflow();
  }
}

.out-sign-field-options-container {
  .esign-auto-complete-options-container();
  .person-info {
    display: flex;
    justify-content: flex-start;
    .name {
      padding-right: 3px;
    }
  }
  .company {
    .text-overflow();
  }
}