//@import '../viewer/style/viewer.variable.scss';
//@import '../Shared/Shared.css.var.scss';
//@import '../Shared//Shared.variable.scss';
@import '../theme.css';
@media screen and (max-width:1150px) {
  .EsignDoc{
    //padding:35px 20px!important;
  }
}
@media screen and (max-width:1030px) {
  .EsignDoc{
    //padding:35px 10px!important;
  }
  .ant-tabs-nav-wrap{
    margin-left: 20px;
  }
}
body[class='phantom-container'] *:not([support-outline='true']) {
  outline: 0px solid rgba(255, 255, 255) !important;
}
.EsignDoc{
  //padding:35px 40px;
  position: relative;
  .logo{
    cursor: pointer;
    background: url(svg/logo.svg) no-repeat;
    background-size: 100% 100%;
    width: 32px;
    height: 32px;
    position: absolute;
    right:48px;
    top:33px;
    
  }
  .search-form {
    background-color: var(--content-bg-color);
    .base() {
      overflow-y: hidden;
      transition: max-height 0.3s ease;
      padding: 15px 28px;
      margin-bottom: 15px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      border-radius: 5px;
    }
    .base();
    max-height: 162px;
    &-shrink {
      .base();
      max-height: 60px;
    }
    .ant-btn.ant-btn-primary {
      background-color: #982E91;
      border-color: #982E91;
      color: #fff;
    }
    &-btn-group {
      display: flex;
      justify-content: flex-start;
      align-items: center;

    }
    .btn-search-more-base() {
      width: 28px;
      height: 28px;
      cursor: pointer;
      border:  1px #d9d9d9 solid;
      border-radius: 50%;
      box-sizing: border-box;
      text-align: center;
      position: relative;
      margin-right: 10px;
    }
    .btn-more {
      .btn-search-more-base();
      transform: rotate(270deg);
      &:hover {
        background-color: #fcdafe;
        border-color: #e2a1e6;
        transition: background-color 0.3s ease, transform 0.3s;
        .btn-more-icon1, .btn-more-icon2 {
          border-color: #e48bea;
        }
      }
      .btn-more-icon() {
        display: inline-block;
        width: 7px;
        height: 7px;
        line-height: 20px;
        border-top: 1px solid gray;
        border-right: 1px solid gray;
        transform: rotate(45deg);
        transition: transform 0.3s ease;
        position: absolute;
      }
      &-icon1 {
        .btn-more-icon();
        top: 9px;
        left: 6px;
      }
      &-icon2 {
        .btn-more-icon();
        top: 9px;
        left: 10px;
      }
      &-close {
        .btn-search-more-base();
        transform: rotate(90deg);
        &:hover {
          background-color: #fcdafe;
          border-color: #e2a1e6;
          transition: background-color 0.3s ease, transform 0.3s;
          .btn-more-icon1, .btn-more-icon2 {
            border-color: #e48bea;
          }
        }
      }
    }
  }

  .tool-bar {
    display: flex;
    justify-content: flex-start;
    .table-type-btn-wrap {
      margin-right: 10px;
      display: flex;
      flex-wrap: nowrap;
      .custom-active {
        background: transparent;
        border-color: #F290F4;
        color: var(--primary-color);
      }
      .custom-normal {
        border-color: #E0E0E0;
        background: var(--radio-btn-bg-color);
      }
      .ant-btn {
        margin-right: 16px;
        border-radius: 25px;
      }
    }
  }

  .searchBtnBox{
    position: absolute;right:98px;top: 33px;height: 28px;
    width: 161px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-right: 15px;
    .searchBoxIcon{
      height: 20px;
      width: 20px;
      z-index: 1;
      background: url('svg/search2.svg') 0 0 / 100% 100% no-repeat;
    }
    .searchBtnItem {
      &:hover {
        border-color: #e2a1e6 !important;
        border-right-width: 1px;
        z-index: 1;
      }
      &:focus {
        border-color: #e2a1e6 !important;
        border-right-width: 1px;
        z-index: 1;
      }
      input{
        width: 100%;
        font-size: 12px;
        color: #333333;
        border-radius: 50px;
        padding-left: 10px;
        border-color: #e4e4e4;
         //background: url('./svg/search2.svg') no-repeat 12px 50%;
        background-size: 20px 20px;
        padding-right: 0;
      }
      input::-webkit-input-placeholder{color: #9D9D9D};
      input::-moz-placeholder{color: #9D9D9D};
      input:-moz-placeholder{color: #9D9D9D};
      input:-ms-input-placeholder{color: #9D9D9D};
    }
    .ant-input:hover{
      border-color: #e2a1e6;
    }
    .ant-input:focus {
      border-color: #e2a1e6;
      box-shadow: 0 0 0 2px rgba(228, 139, 234, 0.2);
    }
  
    .search-history-dropdown {
      font-size: 12px;
      max-height: 290px;
      overflow: auto;
      & > div {
        padding: 5px 12px;
      }
      .ant-dropdown-menu-item {
        color: black;
        font-size: 12px;
      }
    }
    .recent-title {
      color: #9D9D9D;
    }
    .clear-btn {
      color: #A236B2;
      text-align: right;
      cursor: pointer;
    }
  }
  .ant-tabs-nav .ant-tabs-tab{
    padding: 12px 2px;
    margin: 0 46px 0 0;
    color:#333333;
    font-weight: 700;
    &:hover{
      color:#A236B2;

    }
  }
  
  .ant-tabs-nav .ant-tabs-tab-active{
    color:#A236B2;
    &:hover{
      color:#A236B2;
    }
  }

  .ant-tabs-ink-bar{
    background-color:#A236B2;
  }
  .ant-table-pagination.ant-pagination{
    float: left!important;
    margin-left: 20px;
  }
}
