.fx-page {
  padding: 8px 0;
}
.fx-page-pad {
  bottom: 8px;
  left: 0px;
  right: 0px;
  top: 8px;
}
.sign-username {
  position:absolute;
  //left:0;
  //top:-15px;
  text-overflow: clip;
  white-space: nowrap;
  line-height:12px;
  font-size:12px;
  color: white;
  padding: 3px;
  border-radius: 3px;
}

.date-input{
    position: relative;
}

.date-input::-webkit-calendar-picker-indicator{
     position: absolute;
     right: 0;
     height: 100%;
     padding-left: 100%;
     padding-right: 0px;
 }

.sign-drapbox {
  z-index:20;
  position: absolute;
  overflow-y: hidden;
  padding: 10px 15px 10px 5px;
  background: #FFFFFF;
  border: solid 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.2);

  .setting {
    font-weight: normal;
    color: #8C8C8C;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px 0 5px 10px;
    border-top: 1px #E8E8E8 solid;
    i {
      margin-right: 5px;
      width: 16px;
      height: 16px;
      background-repeat: no-repeat;
      background-size: 16px 16px;
      background-position: center;
      display: inline-block;
      background-image: url("../../assets/images/setting.svg");
    }
    &:hover {
      background-color: #E8E8E8;
    }
  }
  .create {
    border-top: unset;
    i {
      background-image: url("../../assets/images/pencil.svg");
    }
  }
  .date-create {
    border-top: unset;
    i {
      background-image: url("../../assets/images/date_field_16.svg");
    }
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    height: 193px;
    overflow-y: auto;
    //justify-content: space-between;
    .noData {
      display: flex;
      align-items: flex-end;
      margin-bottom: 5%;
      font-weight: 400;
      width: 100%;
      font-size: 16px;
      color:  #828282;
      justify-content: center;
      background-image: url("../../assets/images/pencil2.png");
      background-repeat: no-repeat;
      background-size: 40px 36px;
      background-position: center;
    }
    .item {
      position: relative;
      width: 84px;
      height: 84px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin: 0 0 10px 10px;
      padding: 2px;
      border: 1px #E8E8E8 solid !important;
      border-radius: 4px;
      &:hover {
        border-color: rgba(210, 133, 229, 0.8) !important;
      }

      .triangle {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 20;
        width: 20px;
        height: 16px;

        background-color: #A236B2;
        //clip-path: polygon(0 0, 100% 0, 100% 100%);
        color: white;
        display: flex;

        // justify-content: flex-end;
        justify-content: center;
        //align-items: center;
        div {
          width: 12px;
          height: 12px;
          font-size: 12px;
          //transform: rotate(45deg);
        }
      }

      .img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -o-user-select: none;
        user-select: none;
      }
    }
  }


}

.date-drag-pad {
  z-index: 2;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  overflow: hidden;
}

.date-drag {
  position: absolute;
  z-index: 3;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .date-opt-panel {
    position: absolute;
    right: -4px;
    top: -48px;
    width: 78px;
    height: 40px;
    border-radius: 2px;
    border: 1px solid #DDD;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
    display: none;
    justify-content: space-between;
    align-items: center;
    i {
      width: 30px;
      height: 30px;
      border-radius: 4px;
      margin: 4px;
      background-repeat: no-repeat;
      background-size: 16px 16px;
      background-position: center;
      display: block;
      &:hover {
        background-color: #E8E8E8;
      }
    }
    .date-opt-add {
      background-image: url("../../assets/images/date.svg");
    }
    .date-opt-del {
        background-image: url("../../assets/images/delete.svg");
    }
  }
  .date-drag-point {
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: #5aa;
    pointer-events: initial;
    display: none;
  }

  .date-drag-point2 {
    background-color: #D70B0B;
  }

  .date-drag-point-left-top {
    left: -4px;
    top: -4px;
    cursor: nw-resize;
  }

  .date-drag-point-right-top {
    right: -4px;
    top: -4px;
    cursor: ne-resize;
  }

  .date-drag-point-left-bottom {
    left: -4px;
    bottom: -4px;
    cursor: sw-resize;
  }

  .date-drag-point-right-bottom {
    right: -4px;
    bottom: -4px;
    cursor: se-resize;
  }

  .date-drag-point-right {
    right: -4px;
    bottom: 50%;
    cursor: e-resize;
  }

  .date-drag-point-bottom {
    right: 50%;
    bottom: -4px;
    cursor: s-resize;
  }

  .date-drag-point-top {
    right: 50%;
    top: -4px;
    cursor: n-resize;
  }

  .date-drag-point-left {
    left: -4px;
    bottom: 50%;
    cursor: w-resize;
  }
}

.date-drag-active {
  outline: #5aa dashed 1px;
  .date-drag-point {
    display: block;
  }
}

.date-drag-active2 {
  outline: #D70B0B dashed 1px;;
}












