body {
  height: auto;
}
.main {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 355px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .title {
    margin-top: 15px;
    font-weight: 700;
    font-size: 20px;
  }
  .tips {
    margin: 25px 0 20px;
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    text-align: center;
  }
  .loading, .hasError {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  .loadingBg, .errorBg{
    width: 128px;
    height: 128px;
    border-radius: 64px;
    background: #EDB4FB;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @-webkit-keyframes loadingCircle {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes loadingCircle {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }


  .loadingIcon {
    background-image: url("../../../assets/images/loading2.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 64px;
    width: 64px;
    height: 64px;
    -webkit-animation: loadingCircle 1.6s infinite linear;
    animation: loadingCircle 1.6s infinite linear;
  }
  .errorIcon {
    background-image: url(../../../assets/images/error.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 264px;
    height: 164px;
  }

  .loadingText, .hasErrorText {
    margin: 0;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 40px;
    text-align: center;
    color: #222222;
  }
}
