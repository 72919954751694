.ant-message {
  z-index: 99999 !important;
}
.ant-modal-wrap {
  overflow: hidden;
}
.sign-cn-stamp-apply-dialog {
  width: 640px;
  .admin-btn {
    width: 80px;
    height: 22px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .btn-color {
    color: #A236B2;
  }
  .btn-hover:hover {
    border-bottom: 1px #A236B2 solid;
    box-sizing: content-box;
  }
  .stamp-apply__header {
    height: 24px;
    display: flex;
    margin-bottom: 15px;
    justify-content: space-between;
    .stamp-apply__header-left {
      display: flex;
      justify-content: center;
      align-content: center;
    }
    .stamp-apply__refresh-btn {
      margin-right: 5px;
      margin-bottom: 2px;
      cursor: pointer;
      &:hover {
        border-bottom: 1px #A236B2 solid;
      }
    }
  }
  .stamp-apply-container {
    height: 349px;
    overflow-y: auto;
    background-color: #F5F5F5;
    border: 1px solid #C5C5C5;
    border-radius: 4px;
    padding: 14px 8px 14px 14px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    position: relative;
    .spin-loading {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      background: #fff;
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    .stamp-list-msg {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      background: #fff;
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .sign-cn-stamp-apply__stamp-container {
      position: relative;
      width: 31%;
      height: 100px;
      border: 1px #d9d9d9 solid;
      background-color: #fff;
      background-repeat: no-repeat;
      background-position: center 20%;
      background-size: auto 50px;
      margin: 0 12px 12px 0;
      &:nth-child(3n) {
        margin-right: 0;
      }
      .no-permission_wraper {
        opacity: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.27);
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        button {
          border-radius: 28px;
        }
        &:hover {
          opacity: 1;
        }
      }
      .stamp-tag {
        background-color: #FFA234;
        position: absolute;
        right: 0;
        top: 0;
        font-weight: 600;
        font-size: 11px;
        line-height: 15px;
        color: #fff;
        padding: 2px;
      }
      .stamp-alias {
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 80px;
        white-space: nowrap;
        position: absolute;
        bottom: 3px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 99999;
      }
    }
    .org-stamp {
      background-repeat: no-repeat;
      background-position: center 20%;
      background-size: auto 55px;
    }
  }
}

.eSignCN_notifcication_wraper {
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  img {
    vertical-align: middle;
    margin-right: 20px;
  }
}
.ant-notification:has(.eSignCN_notifcication_wraper) {
  z-index: 999999 !important;
  top: 40px !important;
  right: 16px !important;
  margin-right: 0 !important;
}

.fix_css {
  z-index: 999999 !important;
  top: 40px !important;
  right: 16px !important;
  margin-right: 0 !important;
}