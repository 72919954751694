.list{
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  margin: 5px 0 0 -5px;
  min-height: 100px;
  .noData {
    display: flex;
    align-items: flex-end;
    margin-bottom: 5%;
    font-weight: 400;
    width: 100%;
    font-size: 14px;
    color:  #828282;
    justify-content: center;
    background-image: url("../../../assets/images/pencil2.png");
    background-repeat: no-repeat;
    background-size: 40px 36px;
    background-position: center;
  }
  .addIcon {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .img {
      width: 40px;
      height: 36px;
      background-image: url("../../../assets/images/pencil3.svg");
      background-repeat: no-repeat;
      background-size: 40px 36px;
      background-position: center;
    }
    .title {
      font-size: 14px;
      margin-top: 8px;
    }
  }
  .item {
    position: relative;
    width: 84px;
    height: 84px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0 0 10px 10px;
    padding: 2px;
    border: 1px #E8E8E8 solid !important;
    border-radius: 4px;
    &:hover {
      border-color: rgba(210, 133, 229, 0.8) !important;
    }

    .triangle {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 20;
      width: 20px;
      height: 16px;

      background-color: #A236B2;
      //clip-path: polygon(0 0, 100% 0, 100% 100%);
      color: white;
      display: flex;

      // justify-content: flex-end;
      justify-content: center;
      //align-items: center;
      div {
        width: 12px;
        height: 12px;
        font-size: 12px;
        //transform: rotate(45deg);
      }
    }

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      -moz-user-select: none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      -o-user-select: none;
      user-select: none;
    }
  }
}

.setting {
  font-weight: normal;
  color: #8C8C8C;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px 0 5px 10px;
  i {
    margin-right: 5px;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-position: center;
    display: inline-block;
    background-image: url("../../../assets/images/setting.svg");
  }
  &:hover {
    background-color: #E8E8E8;
  }
}