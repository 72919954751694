.iframe {
  width: 100%;
  border: 0;
}

.loading {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff4d4f;
  font-size: 16px;
  svg {
    font-size: 20px;
  }
}

.tabs {
  margin: 0 20px 10px;
}

.tabFooter {
  display: flex;
  border-top: 1px solid #f0f0f0;
  height: 52px;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  button {
    margin-left: 20px;
    width: 90px;
  }
}

.tabContainer {
  height: 300px;
  background-color: #F5F5F5;
  padding: 10px;
}

.tabInput2 {

}

.tabInput3 {
  p {
    margin: 0px;
    padding: 10px;
    line-height: 25px;
    font-size: 25px;
    cursor: default;
    min-width: 160px;
    text-align: center;
    &:hover {
      background-color: #F5F5F5;
    }
  }
}

.phantomTabInput3 {
  max-height: 280px;
  overflow: auto;
  p {
    margin: 0px;
    padding: 10px;
    line-height: 25px;
    font-size: 25px;
    cursor: default;
    min-width: 160px;
    text-align: center;
    &:hover {
      background-color: #F5F5F5;
    }
  }
}

.tabInput {
  height: 260px;
  line-height: 260px;
  font-size: 100px;
  text-align: center;
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
}

.tabDraw {
  canvas {
    height: 100%;
    width: 100%;
  }
}

.tabCropper {
  margin: 0 20px 10px 20px;
  padding: 0;
}

.tabUpload {
  :global {
    .ant-upload.ant-upload-drag .ant-upload {
      padding: 0;
    }
  }
  img {
    max-width: calc(100% - 20px);
    max-height: 280px;
  }
  .upload {
    height: 280px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    color: #A236B299;
    svg {
      width: 60px;
      height: 60px;
    }

  }
}

.innerBox {
  width: 100%;
  min-height: 50vh;
  max-height: 80vh;
  overflow-y: auto;
  .addBtn {
    padding: 5px 20px;
    text-align: right;
  }
  .list {
    //border: 1px solid #DDDDDD;
    //background-color: #F5F5F5;
    margin: 0  0 20px 20px;
    display: flex;
    flex-wrap: wrap;
    //gap: 20px;
    //padding: 20px 0 20px 20px;
    .item {
      margin: 0 20px 20px 0;
      position: relative;
      width: calc(25% - 20px);
      height: 100px;
      //background-color: #fff;
      outline: 1px #DDDDDD solid !important;
      cursor: pointer;
      &:hover,
      &:active {
        .cover {
          background-color: rgba(0, 0, 0, 0.2);
          .delBtn {
            display: block;
          }
        }
      }
      .cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        //background-color: rgba(0, 0, 0, 0);
        display: flex;
        justify-content: center;
        align-items: center;
        .delBtn {
          display: none;
        }
      }
      .img {
        width: 100%;
        height: 100%;
        padding: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

    }
  }
  .emptyContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    .emptyIcon {
      width: 109px;
      height: 109px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url('../../../assets/images/empty_signature_list_icon.svg');
      margin-bottom: 40px;
    }
  }
}
