@import '../../index.css';

html {
    --bg-color-rgb: #fbfbfd;
    --radio-btn-bg-color: #F7F7F7;
    --btn-bg-color: #fff;
    --btn-color: #141414;
    --btn-primary-bg-color: var(--primary-color);
    --btn-bg-color-hover: #f5f5f5;
    --content-bg-color: #fff;
    --content-bg-color-second: #F5F6FA;
    --popover-bg-color: #fff;
    --popover-font-color: #141414;
    --input-border-color: #d9d9d9;
    --antd-date-picker-range-bg-color: #fef0ff;
    --link-text-color: #ff87f6a6;
    --link-text-color-hover: #ee8be8;
}
html::-webkit-scrollbar-track {
    background-color: var(--primary-color); /* 设置webkit内核浏览器滚动条滑道的背景色 */
}
html::-webkit-scrollbar-thumb {
    background-color: #888; /* 滑块颜色 */
    border-radius: 5px; /* 滑块圆角 */
}
html[data-theme="dark"] {
    --bg-color-rgb: #242424;
    --radio-btn-bg-color: #4f4b4b;
    --btn-bg-color: #525252;
    --btn-color: #FFF;
    --btn-primary-bg-color: #c34cea;
    --btn-bg-color-hover: #ffffff14;
    --content-bg-color: #141414;
    --content-bg-color-second: #5f5f5f;
    --popover-bg-color: #525252;
    --popover-font-color: #fff;
    --input-border-color: #434343;
    --antd-date-picker-range-bg-color: #4f4450;
    --link-text-color: #ff87f6a6;
    --link-text-color-hover: #ee8be8;
}

html[data-theme="dark"] body {
    scrollbar-color: #666 #000;
    scrollbar-arrow-color: #000;
    -ms-scrollbar-arrow-color: #000;
    -ms-scrollbar-base-color: black;
}

.ant-tabs-ink-bar {
    background-color: var(--primary-color) !important;
}
.ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:focus, .ant-tabs-tab-btn:active, .ant-tabs-tab-remove:active {
    color: var(--primary-color) !important;
}
.ant-picker:hover, .ant-picker-focused {
    border-color: var(--primary-color) !important;
}
.ant-picker-range .ant-picker-active-bar {
    background-color: var(--primary-color) !important;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: var(--primary-color) !important;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: var(--primary-color) !important;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: var(--primary-color) !important;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: var(--primary-color) !important;
}
.ant-btn:hover, .ant-btn:focus {
    color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}
.ant-pagination-item:hover {
    border-color: var(--primary-color) !important;
    transition: all 0.3s;
}
.ant-pagination-item:hover a {
    color: var(--primary-color) !important;
}
.ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
    color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: var(--primary-color) !important;
}
.ant-pagination-options-quick-jumper input:hover {
    border-color: var(--primary-color) !important;
}
.ant-select-item-option:not(.ant-select-item-option-disabled) {
    color: var(--btn-color) !important;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: var(--btn-bg-color-hover) !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    border-color: var(--primary-color) !important;
    color: var(--primary-color) !important;
    background-color: var(--btn-bg-color) !important;
}
.ant-picker-input > input-focused {
    border-color: var(--primary-color) !important;
    box-shadow: 0 0 0 2px var(--primary-color);
    border-right-width: 1px;
    outline: 0;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused, .ant-pagination-options-quick-jumper input:focus, .ant-pagination-options-quick-jumper input-focused {
    border-color: var(--primary-color) !important;
}
.esign_table_dropdown .ant-btn.ant-btn-primary:not([disabled]) {
    background-color: transparent !important;
}
.ant-spin-dot-item {
    background-color: var(--primary-color) !important;
}
.ant-input:focus, .ant-input-focused, .ant-input:hover {
    border-color: var(--primary-color) !important;
}
.ant-btn-primary {
    background-color: var(--btn-primary-bg-color) !important;
    border-color: var(--btn-primary-bg-color) !important;
    outline: none !important;
}
.ant-btn-primary:hover, .ant-btn-primary:focus {
    color: #fff !important;
}
.ant-picker-today-btn {
    color: var(--primary-color) !important;
}
.ant-input-affix-wrapper, .ant-input, .ant-input-group-addon, .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid var(--input-border-color);
}

.ant-tooltip .ant-tooltip-inner {
    color: var(--popover-font-color) !important;
    background-color: var(--popover-bg-color) !important;
}
.ant-tooltip-arrow-content {
    --antd-arrow-background-color: var(--popover-bg-color) !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--primary-color) !important;
}

.ant-modal, .ant-modal-content {
    background-color: var(--content-bg-color) !important;
    border: 1px solid #C7CCD9 !important;
    padding-bottom: 0 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background-color: var(--antd-date-picker-range-bg-color) !important;
}

.ant-picker-header-view button:hover {
    color: var(--primary-color) !important;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: #982E91 !important;
}

body {
    background-color: var(--bg-color-rgb) !important;
}