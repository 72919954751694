//@import '../../viewer/style/viewer.variable.scss';
//@import '../../Shared/Shared.css.var.scss';
//@import '../../Shared//Shared.variable.scss';
body[class='phantom-container'] *:not([support-outline='true']) {
  outline: 0px solid rgba(255, 255, 255) !important;
}
.round{
  width: 20px;
  height: 20px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
 .textPortrait {
   .round();
   background: #B0B0B0;
   text-align: center;
   color: #fff;
   font-size: 12px;
}
 .userPortrait {
  color: #fff;
   border-radius: 50%;
   height: 20px;
   width: 20px;
}
 .BeyondHidden {
   overflow: hidden;
   white-space: nowrap;
   text-overflow: ellipsis;
 }
 .cleanFloat {
   &:after{
     content: '';
     display: block;
     clear:both;
   }
 }
  .GrayColor {
   color: #707070;;
 }
 .popBox{
  .title{
    .GrayColor();
    font-size: 14px;
    text-align: left;
    border-bottom: 1px solid #E4E4E4;
    margin-bottom: 0;
    padding-bottom: 12px;
    .cleanFloat();
    .successMsg{
      float: right;
      color: #A236B2;
      font-size: 12px;
    }
  }
  .addUserBox{
    border-bottom: 1px solid #E4E4E4;
    .addBtnBox{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 50px;
      .addIcon{
        cursor: pointer;
        background: #A236B2;
        .userPortrait();
        text-align: center;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      span{
        color: #333333;
        font-size: 12px;
        cursor: pointer;
        padding:12px
      }
    }
    .ant-spin-nested-loading > div > .ant-spin {
      position: absolute;
    }
    .addBox{
      padding: 10px 0 0 0;
      .cleanFloat();
      .ant-select-selection__clear{
        display: none;
      }
      .ant-select-show-search{
        width: 100%
      }
      .ant-select-selection__placeholder {
        padding-left: 26px;
      }
      .ant-select-search__field__wrap{
        .ant-input{
          border-radius: 50px;padding-left: 40px;
          background: url('../svg/search2.svg') no-repeat 12px 50%;
          background-size: 20px;
        }
      }
      .ant-legacy-form{
        .ant-legacy-form-item{
          margin-bottom:0px !important;
        }
      }
      //@include primary-button('.addBtn'){
      //  @include normal();
      //  float:right;
      //  margin: 15px 0;
      //}
      // .addBtn{width: 72px;height: 26px;border-radius: 13px;background-color: #A236B2;color: #fff;float:right;margin: 15px 0;}
      .selectUserList{
        list-style: none;
        padding-left: 0;
        display: flex;
        justify-content: flex-start;
        margin: 0;
        flex-wrap: wrap;
        max-height: 70px;
        overflow-y: auto;
        overflow-x: hidden;
        //&::-webkit-scrollbar {width:5px;}
        //&::-webkit-scrollbar-thumb {background: #eee;}
        li{
          max-width:180px;
          height: 24px;border-radius: 13px;
          border: 1px solid #E4E4E4;
          padding: 0px 26px 0px 8px;
          position: relative;
          .BeyondHidden();
          margin: 8px 8px 0 0;
          span{
            position: absolute;
            right: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .userList{
    list-style: none;padding: 0; overflow: auto;margin-top: 8px;
    li{
      display: flex;align-items: center;
      margin-bottom:10px;
      .headPortrait{
        .guest-avatar{
          background: url('../svg/guest.svg') 0 0 / 100% 100% no-repeat;
        }
        .user-avatar {
          display: inline-block;
          width: 20px;
          height: 20px;
          border: 1px solid #fff;
          border-radius: 50px;
        }
        margin-right:8px;
        span{
          .userPortrait();
          background: #9DB4E2;
          display: block;
          text-align: center;
          line-height: 17px;
          border: 1px solid #fff;
        }
        img{
          .userPortrait();
          margin-left: 0;
          margin-right: 0;
        }
      }
      .content{
        width: 100%;
        p{
          width:85%;
          margin-bottom: 0;
          font-size: 12px;
          .BeyondHidden();
          .GrayColor();
        }
        .identity{
          color: #333;font-size: 12px;
        }
        .identity-gray{
          color: #A5A5A5;
        }
        span{
          display: block;
          margin-top:-5px;
        }
      }
    }
  }
}
.signerList {
  .urgeBtn {
    color: #ffffff;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #A236B2;
    border-radius: 4px;
    cursor: pointer;
  }
  .guest-avatar{
    background: url('../svg/guest.svg') 0 0 / 100% 100% no-repeat;
  }
  .user-avatar {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid #fff;
    border-radius: 50px;
  }
  .defaultPortrait{
    .textPortrait();
    margin: 0;
    background: #9DB4E2;
    cursor: context-menu;
    cursor: pointer;
  }
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 4px;
  line-height: 1.5;
  .total-user {
    flex-shrink: 0;
    font-size: 12px;
    color: #ffffff;
    margin-right: 14px;
    line-height: 20px;
    // min-width: 64px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    text-align: center;
    background: #B0B0B0;
  }
  .totalUser{
    .textPortrait();
    margin-right: 6px;
    cursor: pointer;
  }
  .moreTotal{
    width: 30px;
    padding: 0 2px;
  }
  .portraitList{
    list-style: none;
    margin-bottom:0;
    display: flex;
    align-items: center;
    padding:0 4px 0 0;overflow: hidden;
    max-width: calc(100% - 6px);
    li{
      margin-right: 1px;
      font-size: 0;
      .defaultPortrait{
        .textPortrait();
        margin: 0;
        background: #9DB4E2;
        cursor: context-menu;
        cursor: pointer;
      }
      .img{
        .round();
      }
      .user_Portrait{
        .round();
        margin: 0;
        cursor: pointer;
      }
      .ant-tooltip-inner {
        color: #999;
      }
    }
  }
  .morePortraitList{
    max-width: calc(100% - 38px);
  }
  .right-arrow-btn {
    display: inline-block;
    width: 6px;
    height: 11px;
    background: url('../svg/right-arrow.svg') 0 0 / 100% 100% no-repeat;
    cursor: pointer;
    transform: rotate(90deg);
    margin-top: 5px
  }
  .noArrow {
    padding: 0;
    &.ant-popover {

    }
    .ant-popover-arrow{
      display: none;
    }
    .ant-popover-inner{
      border: none;
      box-shadow: none;
    }
  }
}

.userListDialog{
  position: fixed;
  width: 200px;
  height: auto;
  left:0;
  top:0;
  background: #fff;
  z-index: 2;
  padding: 10px;
  .sharedPopoverBox{
    .popBox();
    width: 100%;
  }
}
.sharedPopoverBox{
   .popBox();
    width: 800px!important;
    max-height: 212px !important;
    .tableTitle {
      height: 34px;
      align-items: center;
      display: flex;
      span {
        font-weight: bold;
      }
    }
  .userList {
    max-height: 168px !important;
    li {
      border-top: solid 1px rgba(238, 238, 238, 1);
      line-height: 55px;
      margin: 0;
      .urgeBtn {
        color: #ffffff;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #A236B2;
        border-radius: 4px;
        cursor: pointer;
      }
      .content {
        width: 76%;

        p{width: 100%;}
      }
    }
  }
}

