@import 'antd/dist/antd.variable.min.css';
/*@import 'antd/dist/antd.dark.css';*/
html {
  /* #fbfbfd */
  --bg-color-rgb: 251, 251, 253;
  --bg-color: rgb(var(--bg-color-rgb));
  /* #333 */
  --text-color-rgb: 51, 51, 51;
  --text-color: rgb(var(--text-color-rgb));
  /* #F9F9F9 */
  --bg-second-color-rgb: 249, 249, 249;
  --bg-second-color: rgb(var(--bg-second-color-rgb));
  /* #EBEBEB */
  --border-color-rgb: 235, 235, 235;
  --border-color: rgb(var(--border-color-rgb));
  /* #A236B2 */
  --primary-color-rgb: 162, 54, 178;
  --primary-color: rgb(var(--primary-color-rgb));
  /* #515151 */
  --seal-bg-color-rgb: 255, 255, 255;
  --seal-bg-color: rgb(var(--seal-bg-color-rgb));

  --input-color: #515151;
  --info-text: #518FE5;
}
html[data-theme="dark"] {
  /* #333 */
  --bg-color-rgb: 51, 51, 51;
  --bg-color: rgb(var(--bg-color-rgb));
  /* #fff */
  --text-color-rgb: 255, 255, 255;
  --text-color: rgb(var(--text-color-rgb));
  /* #242424 */
  --bg-second-color-rgb: 36, 36, 36;
  --bg-second-color: rgb(var(--bg-second-color-rgb));
  /* #666666 */
  --border-color-rgb: 102, 102, 102;
  --border-color: rgb(var(--border-color-rgb));
  /* #D4A1E5 */
  --primary-color-rgb: 212, 161, 229;
  --primary-color: rgb(var(--primary-color-rgb));
  /* #515151 */
  --seal-bg-color-rgb: 81, 81, 81;
  --seal-bg-color: rgb(var(--seal-bg-color-rgb));

  --input-color: #bdbdbd;
  --info-text: #99EBFF;
}

.ant-tabs {
  color: rgba(var(--text-color-rgb), .85);
}

.ant-modal-header {
  background-color: var(--bg-color);
}

.ant-modal-title {
  color: rgba(var(--text-color-rgb), .85);
}

.ant-modal-content {
  background-color: var(--bg-color);
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  color: rgba(var(--text-color-rgb), .85);
}

.ant-modal-confirm-body .ant-modal-confirm-content {
  color: rgba(var(--text-color-rgb), .85);
}

.ant-input-affix-wrapper, .ant-input, .ant-input-group-addon {
  background-color: transparent;
  border: var(--input-color) solid 1px;
  color: var(--input-color);
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent;
  border: var(--input-color) solid 1px;
  color: var(--input-color);
}

.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  background-color: transparent !important;
}

.ant-select-clear {
  background-color: transparent !important;
}

.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
  background-color: transparent !important;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
  background-color: transparent !important;
}


.anticon-close-circle {
  color: var(--input-color);
}

body {
  margin: 0;
  padding: 0;
  border: 0;
}
