.ant-modal-wrap {
  overflow: hidden;
}
.sign-cn-stamp-manage-dialog {
  width: 640px;
  .admin-btn {
    width: 80px;
    height: 22px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .btn-color {
    color: #A236B2;
  }
  .btn-hover:hover {
    border-bottom: 1px #A236B2 solid;
    box-sizing: content-box;
  }
  .stamp-manage__header {
    height: 24px;
    display: flex;
    margin-bottom: 15px;
    justify-content: space-between;
    .stamp-manage__header-left {
      display: flex;
      justify-content: center;
      align-content: center;
    }
    .stamp-manage__header-right {
      display: flex;
      justify-content: center;
      align-content: center;
      .org-admin-btn-wrap {
        display: flex;
        justify-content: center;
        align-content: center;
      }
    }
    .stamp-manage__line {
      color: #A236B2;
      padding: 0 3px;
    }
    .icon {
      width: 16px;
      height: 16px;
      background-repeat: no-repeat;
      background-size: 16px 16px;
      background-position: center;
      color: #A236B2;
    }
    .setting-icon {
      display: inline-block;
      background-image: url("assets/setting.svg");
      -webkit-animation: loadingCircle 1s infinite linear;
      animation: loadingCircle 1s infinite linear;
      background-color: transparent;
    }
    .stamp-manage__refresh-btn-icon {
      width: 18px;
      height: 18px;
      display: inline-block;
      background-image: url("assets/refreshBtn.svg");
      background-position: center;
      background-size: 16px auto;
      background-repeat: no-repeat;
      margin-right: 2px;
      position: relative;
      top: 3px;
    }
    .stamp-manage__refresh-btn {
      margin-right: 5px;
      margin-bottom: 2px;
      cursor: pointer;
      &:hover {
        border-bottom: 1px #A236B2 solid;
      }
    }
    .stamp-manage__refresh-loading {
      animation: loadingCircle 1s infinite linear;
      -webkit-animation: loadingCircle 1s infinite linear;
    }
    @-webkit-keyframes loadingCircle {
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @keyframes loadingCircle {
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }
  .stamp-manage-container {
    height: 313px;
    overflow-y: auto;
    background-color: #F5F5F5;
    border: 1px solid #C5C5C5;
    border-radius: 4px;
    padding: 14px 8px 14px 14px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    position: relative;
    .spin-loading {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      background: #fff;
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    .stamp-list-msg {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      background: #fff;
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .sign-cn-stamp-manage__stamp-container {
      position: relative;
      width: 31%;
      height: 100px;
      border: 1px #d9d9d9 solid;
      background-color: #fff;
      background-repeat: no-repeat;
      background-position: center 20%;
      background-size: auto 50px;
      margin: 0 12px 12px 0;
      &:nth-child(3n) {
        margin-right: 0;
      }
      .sign-cn-stamp-manage__stamp-type {
        text-align: center;
        //background-color: rgba(0, 0, 0, 0.5);
        color: #333333;
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
      }
      .stamp-alias {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 80px;
        white-space: nowrap;
      }
    }
    .org-stamp {
      background-repeat: no-repeat;
      background-position: center 20%;
      background-size: auto 55px;
    }
  }
}