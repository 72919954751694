.ant-message {
  z-index: 99999 !important;
}
body[name='stamp-manage-container'] {
  .ant-modal-wrap {
    width: 100vw !important;
    height: 100vh !important;
    right: auto !important;
    bottom: auto !important;
  }
  .phantom-stamp-manage {
    width: 100vw !important;
    max-width: 100vw;
    margin: 0;
    .ant-modal-close, .ant-modal-header {
      display: none;
    }
    .ant-modal {
      height: 100vh !important;
      margin: 0;
    }
    .ant-modal-body {
      width: 100vw;
      height: 100vh;
      padding-top: 10px;
      border-top: 1px solid #f0f0f0;
      >div {
        max-height: 100vh !important;
      }
    }
    .ant-modal-content {
      height: 100vh;
    }
  }
}

body[name='stamp-add-container'] {
  .ant-modal-mask {
    display: none;
    width: 1000px;
    height: 425px;
  }
  .ant-modal-wrap {
    width: 1000px;
    height: 425px;
    position: fixed;
    top: 0;
    left: 0;
    right: auto !important;
    bottom: auto !important;
  }
  .phantom-stamp-add {
    height: 425px;
    max-width: 100vw;
    margin: 0;
    .ant-modal-close, .ant-modal-header {
      display: none;
    }
    .ant-modal-body {
      padding-top: 10px;
      border-top: 1px solid #f0f0f0;
    }
    .ant-modal-content {
      height: 100%;
      overflow: auto;
    }
  }
  * {
    outline: none;
  }
}

.vc-switch {
  bottom: 153px !important;
}

body[name='add-out-sign-stamp'] {
  .ant-message {
    width: 600px;
  }
  .ant-modal-mask {
    display: none;
  }
  .ant-modal-wrap {
    width: 600px;
    height: 342px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .phantom-add-out-stamp {
    height: 342px;
    max-width: 100vw;
    margin: 0;
    .ant-modal-close, .ant-modal-header {
      display: none;
    }
    .ant-modal-body {
      padding-top: 10px;
      border-top: 1px solid #f0f0f0;
    }
    .ant-modal-content {
      height: 100%;
      overflow: auto;
    }
  }
}

body[name='stamp-apply-container'] {
  .ant-modal-mask {
    width: 600px;
    height: 531px;
  }
  .ant-modal-wrap {
    width: 600px;
    height: 531px;
    right: auto !important;
    bottom: auto !important;
  }
  .sign-cn-stamp-apply-dialog {
    height: 531px;
    max-width: 100vw;
    margin: 0;
    .ant-modal-close, .ant-modal-header {
      display: none;
    }
    .ant-modal-body {
      padding-top: 10px;
      border-top: 1px solid #f0f0f0;
    }
    .ant-modal-content {
      height: 100%;
      overflow: auto;
    }
  }
}

body[name='will-auth-container'] {
  .ant-modal-mask {
    width: 600px;
    height: 355px;
  }
  .ant-modal-wrap {
    width: 600px;
    height: 600px;
    right: auto !important;
    bottom: auto !important;
  }
  .phantom-willing-auth-dialog {
    height: 600px;
    max-width: 100vw;
    margin: 0;
    .ant-modal-close, .ant-modal-header {
      display: none;
    }
    .ant-modal-body {
      padding-top: 10px;
      border-top: 1px solid #f0f0f0;
    }
    .ant-modal-content {
      height: 100%;
      overflow: auto;
    }
  }
}


body[name='out-stamp-manage-container'] {
  .ant-modal-mask {
    width: 600px;
    height: 495px;
  }
  .ant-modal-wrap {
    width: 600px;
    height: 495px;
    right: auto !important;
    bottom: auto !important;
  }
  .out-stamp-manage--dialog {
    height: 495px;
    max-width: 100vw;
    margin: 0;
    .ant-modal-close, .ant-modal-header {
      display: none;
    }
    .ant-modal-body {
      padding-top: 10px;
      border-top: 1px solid #f0f0f0;
    }
    .ant-modal-content {
      height: 100%;
      overflow: auto;
    }
  }
}

body[name='replace-phone'] {
  padding-top: 10px;
  border-top: 1px solid #f0f0f0;
}

.eSignCN_funcDialog_container {
  .ant-modal-confirm-title {
    position: relative;
    top: -15px;
  }
}