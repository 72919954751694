
body {
  background-color: var(--bg-color);
  color: var(--text-color);
}

.chooseType {
  .title {
    line-height: 30px;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    color: var(--text-color);
  }

  .content {
    margin: 20px 30px 0;
    padding: 0 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 227px;
    // background: #F9F9F9;
    background-color: var(--bg-second-color);
    border: 1px solid var(--border-color);
    border-radius: 4px;

    .btn {
      position: relative;
      margin: 0 auto;
      width: 141px;
      height: 141px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      border: 1px solid var(--primary-color);
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.07), 0px -4px 10px rgba(0, 0, 0, 0.07);
      border-radius: 10px;
      cursor: pointer;

      &:hover {
        background-color: var(--bg-color);
        color: var(--primary-color);
      }

      .text {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        margin: 10px 0 0 0;
      }

      .icon {
        display: inline-block;
        background-repeat: no-repeat;
        background-position: center center;
        width: 85px;
        height: 79px;
        background-size: 85px 79px;
      }

      .person {
        background-color: var(--primary-color);
        mask-image: url('./assets/person.svg');
      }

      .org {
        background-color: var(--primary-color);
        mask-image: url('./assets/org.svg');
      }

      .spinLoading {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
        z-index: 1;
        position: absolute;
        top: 20px;
        right: 0;
        bottom: 0;
      }
    }
  }
}

.skeleton {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 10px 20px;
}

.innerBoxContainer {
  width: 100%;
  height: 100vh;
  overflow: hidden;

  .addBtn {
    padding-right: 16px;
    height: 30px;
    color: #982E91;
    text-align: right;
  }

  .innerBox {
    width: 100%;
    height: calc(100vh - 30px);
    overflow-y: auto;

    .list {
      //border: 1px solid #DDDDDD;
      //background-color: #F5F5F5;
      margin: 1px 0 0 20px;
      display: flex;
      flex-wrap: wrap;
      //gap: 20px;
      //padding: 20px 0 20px 20px;
      .item {
        margin: 0 20px 20px 0;
        position: relative;
        width: calc(25% - 20px);
        height: 100px;
        background-color: var(--seal-bg-color);
        outline: 1px #DDDDDD solid !important;
        cursor: pointer;

        &:hover,
        &:active {
          .cover {
            background-color: rgba(0, 0, 0, 0.2);

            .delBtn {
              display: block;
            }
          }
        }

        .cover {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          //background-color: rgba(0, 0, 0, 0);
          display: flex;
          justify-content: center;
          align-items: center;

          .delBtn {
            display: none;
          }
        }

        .img {
          width: 100%;
          height: 100%;
          padding: 2px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }

      }
    }

    .emptyContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;

      .emptyIcon {
        width: 109px;
        height: 109px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url('./assets/empty.svg');
        margin-bottom: 40px;
      }
    }
  }
}

.applyDialog {
  .admin-btn {
    width: 80px;
    height: 22px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .spin-loading {
    display: flex;
    justify-content: center;
    margin-top: 100px;
  }

  .btn-color {
    color: #A236B2;
  }

  .btn-hover:hover {
    border-bottom: 1px #A236B2 solid;
    box-sizing: content-box;
  }

  .stamp-apply-container {
    overflow: hidden;
    padding: 0 20px;
    position: relative;
    user-select: none;

    .header {
      height: 24px;
      display: flex;
      margin-bottom: 10px;
      justify-content: space-between;

      .left {
        display: flex;
        justify-content: center;
        align-content: center;
        color: grey;
      }

      .stamp-apply__refresh-btn {
        margin-right: 5px;
        margin-bottom: 2px;
        cursor: pointer;

        &:hover {
          border-bottom: 1px #A236B2 solid;
        }
      }
    }

    .stamp-list-msg {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      background: #fff;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      color: gray;
    }

    .list {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 28px;
      height: 392px;
      overflow-y: auto;

      .item {
        position: relative;
        width: 224px;
        height: 182px;
        border: 1px #d9d9d9 solid;
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: center 40%;
        background-size: 120px 120px;
        margin: 0;

        &:nth-child(3n) {
          margin-right: 0;
        }

        .no-permission_wraper {
          opacity: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.27);
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;

          button {
            border-radius: 28px;
          }

          &:hover {
            opacity: 1;
          }
        }

        .stamp-tag {
          background-color: rgba(255, 168, 17, .1);
          color: #FFA811;

          position: absolute;
          z-index: 20;
          top: 0;
          left: 0;
          min-width: 64px;
          padding: 0 6px;
          height: 24px;
          text-align: center;
          line-height: 24px;
          font-size: 12px;
          font-weight: 400;
        }

        .stamp-tag-success {
          position: absolute;
          z-index: 20;
          top: 0;
          left: 0;
          min-width: 64px;
          padding: 0 6px;
          height: 24px;
          text-align: center;
          line-height: 24px;
          font-size: 12px;
          font-weight: 400;

          background-color: rgba(21, 173, 49, .1);
          color: #15ad31;
        }

        .stamp-alias {
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 80px;
          white-space: nowrap;
          position: absolute;
          bottom: 3px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

  }
}

.iframe {
  width: 100%;
  border: 0;
}

.loading {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff4d4f;
  font-size: 16px;

  svg {
    font-size: 20px;
  }
}

.tabs {
  margin: 0;

  :global {
    .ant-tabs-top > .ant-tabs-nav {
      margin: 0;
    }
  }
}

.tabFooter {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 270px;
  text-align: right;


  button {
    min-width: 80px;
  }
}

.tabFooter2 {
  display: flex;
  height: 52px;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 120px;

  button {
    margin-left: 20px;
    width: 90px;
  }
}

.tabContainer {
  height: 320px;
  background-color: #F5F5F5;
  padding: 10px;
}

.tabInput2 {

}

.tabInput3 {
  display: flex;
  flex-wrap: wrap;
  width: 210px;
  gap: 10px;

  p {
    margin: 0;
    line-height: 40px;
    height: 40px;
    font-size: 26px;
    cursor: default;
    width: 100px;
    text-align: center;

    overflow: hidden;
    white-space: nowrap;

    border-radius: 6px;

    &:hover {
      background-color: #F5F5F5;
    }
  }
}

.tabInput {
  color: #333333;
  height: 260px;
  line-height: 260px;
  font-size: 100px;
  text-align: center;
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;

  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  background: #fafafa;
  margin-top: 16px;
}

.tabDraw {
  .canvas {
    height: 100%;
    width: 100%;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    background: #fafafa;
  }

  .qrContainer {
    .canvas();
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
      margin: 0;
      color: #333333;
    }

    .qrCode {
      margin: 10px;
      width: 176px;
      height: 176px;
      background-size: 100% 100%;

      .success {
        background-color: rgba(255, 255, 255, 0.9);
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .icon {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background-color: #A8C302;
          color: white;
          font-size: 18px;
          justify-content: center;
        }

        p {
          margin-top: 10px;
        }

      }
    }
  }
}

.tabCropper {
  background-color: var(--bg-color);
  p {
    margin: 0 0 5px 0;
    font-weight: 500;
  }
  .icon {
    &:hover {
      color: var(--primary-color);
      cursor: pointer;
    }
  }
  margin: 0;
  padding: 0;
  height: 420px;
  display: flex;
  justify-content: space-between;
  .right {
    flex: 1;
    padding-left: 24px;
    .preview {
      width: 280px;
      height: 280px;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border: solid 1px #d9d9d9;
    }
    .size {
      margin-top: 20px;
    }
  }


}

.tabUpload {
  :global {
    .ant-upload.ant-upload-drag .ant-upload {
      padding: 0;
    }
  }

  img {
    max-width: calc(100% - 20px);
    max-height: 280px;
  }

  .upload {
    height: 288px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    color: #A236B299;
    background: #fafafa;

    svg {
      width: 60px;
      height: 60px;
    }
  }

  .uploadPaste {
    .upload();
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    height: 300px;

    &:hover {
      border-color: #A236B299;
    }
  }
}