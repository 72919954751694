.container {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  box-sizing: border-box;
  background-image: url("../../../assets/images/phantom/auth_promotion.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  .btn {
    background: linear-gradient(0deg,#fff3,#fff3),linear-gradient(180deg,#f718fc66,#7100ca66);
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    height: 32px;
    width: 235px;
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    line-height: 32px;
    text-align: center;
    border: 1px #fff solid;
    &:hover {
      background: linear-gradient(0deg,#ffffff1a,#ffffff1a),linear-gradient(180deg,#f718fc33,#7100ca33);
      cursor: pointer;
    }
  }
  .closeBtn {
    color: #595959;
    display: inline-block;
    font-size: 28px;
    font-weight: 300;
    height: 28px;
    width: 28px;
    position: absolute;
    right: 16px;
    top: 16px;
    background-image: url("../../../assets/images/phantom/close.svg");
    background-position: center;
    background-repeat: no-repeat;
    &:hover {
      background-color: hsla(0,0%,100%,.067);
      border-radius: 50px;
    }
  }
  .noRemain {
    bottom: 20px;
    color: #fee3ff;
    font-size: 12px;
    font-weight: 400;
    left: 45px;
    position: absolute;
    text-decoration-line: underline;
    &:hover {
      text-decoration-line: none;
      cursor: pointer;
    }
  }
}
