@import '../theme.css';

.tabs {
    border-radius: 60px;
    border: 1px solid var(--content-bg-color-second);
    background: var(--content-bg-color-second);
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    width: 180px;
    margin: 0 auto;

    .tab {
        width: 50%;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--text-color);
        border-radius: 60px;
        user-select: none;

        &:hover {
            cursor: pointer;
            color: var(--text-color);
        }
    }

    .tab.active {
        background-color: #A236B2;
        color: white;
        font-weight: 600;
    }
    .tab.disabled {
        color: #ACACAC;
        cursor: not-allowed;
    }
}

.process {
    margin: 30px auto 24px;
    width: 120px;

    .text {
        font-size: 14px;
        line-height: 20px;
        color: var(--color-text-secondary);
    }
}

.date {
    margin: 0 auto;
    color: var(--color-text-secondary);
    text-align: center;
}

.empty {
    margin: 0 auto;
    color: var(--color-text-secondary);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 244px;
    font-size: 14px;
    font-weight: 600;
}

.tips {
    color: var(--color-text-secondary);
    font-size: 12px;
    margin: 0;
    padding: 0 20px;
    line-height: 24px
}
.outType {
    position: absolute;
    top: 114px;
    right: 22px;
    // 自适应内容宽度
    width: auto;
    padding: 0 8px;
    height: 24px;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    color: #fff;
    background-color: var(--primary-color);
}
:global {
    body[key='plugin-container'] {
        .ant-modal-body {
            padding: 12px 24px 24px !important;
        }
        div[esigncn-point-modal="true"] {
            .ant-btn-primary:not(.paper-check-discount-notice-btn, .ant-input-search-button),
            .ant-btn:not(.check-type-btn, .check-engine-btn, .paper-check-discount-notice-btn, .ant-input-search-button) {
                height: 36px !important;
                font-size: 14px !important;
                width: 100%;
                margin: 14px 0;
                font-weight: 600;
                color: #fff !important;
                &:hover {
                    background-color: var(--btn-primary-bg-color) !important;
                }
            }
        }
    }
}


.phantomContainer {
    padding: 24px;
}