:global {
  .vc-switch {
    bottom: 153px !important;
  }
}
.container {
  width: 100%;
  padding: 10px 16px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  :global {
    .ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-title {
      width: 100%;
    }
    .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
      border-color: #ff8925;
      background-color: #ff8925;
    }
  }
  .submitBtn {
    width: 80%;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #A236B2 !important;
    color: #fff !important;
    border-color: #fff !important;
  }
  .guideViewContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #515151;
    line-height: 160%;
    position: absolute;
    top: 50%;
    transform: translateY(-60%);
    .guideViewBg {
      width: 75vw;
      height: 40vh;
      background-image: url(assets/guide_view_bg.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
    h4 {
      font-weight: 600;
    }
    >p {
      margin: 10px 30px;
    }
  }
}
