.stampDropDownContainer {
  width: 330px;
  height: 90vh;
  padding: 10px 15px 5px 20px;
  margin: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 4px;
  border-radius: 3px;

  .dropdownBoxLoading {
    min-height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dropdownBoxMsgContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    .refreshBtn {
      cursor: pointer;
      color: #A236B2;
    }
  }
  .toolBarContainer {
    text-align: right;
    padding: 5px 0;
    .settingIconBtn {
      display: inline-flex;
      flex-direction: row-reverse;
      align-items: center;
      cursor: pointer;
    }
    .icon {
      width: 16px;
      height: 16px;
      background-repeat: no-repeat;
      background-size: 16px 16px;
      background-position: center;
      color: #A236B2;
      cursor: pointer;
    }
    .settingIcon {
      .icon();
      display: inline-block;
      background-image: url("../assets/setting.svg");
      background-color: transparent;
      margin-right: 5px;
    }
    .adminLoading {
      .icon();
      animation: loadingCircle 1s infinite linear;
      -webkit-animation: loadingCircle 1s infinite linear;
    }
    @-webkit-keyframes loadingCircle {
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @keyframes loadingCircle {
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }
  .stampListContainer {
    display: flex;
    flex-wrap: wrap;
    max-height: 60vh;
    overflow: auto;
    .noData {
      width: 100%;
      height: 240px;
      background-image: url("../../../../assets/images/empty_signature_list_icon.svg");
      background-size: 30%;
      background-repeat: no-repeat;
      background-position: 50% 30%;
      position:relative;
      .msg {
        width: 100%;
        color: #a3a0a0;
        position: absolute;
        top: 150px;
        text-align: center;
      }
    }
    .sealsWarp {
      display: flex;
      flex-wrap: wrap;
      .signatureItem {
        width: 47%;
        text-align: center;
        cursor: pointer;
        outline: 1px #eee solid;
        margin-right: 5px;
        margin-bottom: 5px;
        &.in {
          line-height: 56px;
        }
        &:hover,
        &:active {
          outline-color: rgba(210, 133, 229, 0.8) !important;
        }
        .eSignCNStamp {
          width: 100%;
          height: 50px;
          object-fit: contain;
          -moz-user-select: -moz-none;
          -khtml-user-select: none;
          -webkit-user-select: none;
          -o-user-select: none;
          user-select: none;
        }
      }
    }
  }
}


.e-sign-spin {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-varie-sign: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
  position: absolute;
  display: none;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.e-sign-spin-spinning {
  position: static;
  display: inline-block;
  opacity: 1;
}

.e-sign-spin-dot {
  position: relative;
  display: inline-block;
  font-size: 20px;
  width: 1em;
  height: 1em;
}

.e-sign-spin-dot-item {
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  background-color: #e2a1e6;
  border-radius: 100%;
  -webkit-transform: scale(0.75);
  -ms-transform: scale(0.75);
  transform: scale(0.75);
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  opacity: 0.3;
  -webkit-animation: e-signSpinMove 1s infinite linear alternate;
  animation: e-signSpinMove 1s infinite linear alternate;
}

.e-sign-spin-dot-item:nth-child(1) {
  top: 0;
  left: 0;
}

.e-sign-spin-dot-item:nth-child(2) {
  top: 0;
  right: 0;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.e-sign-spin-dot-item:nth-child(3) {
  right: 0;
  bottom: 0;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.e-sign-spin-dot-item:nth-child(4) {
  bottom: 0;
  left: 0;
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.e-sign-spin-dot-spin {
  .e-sign-spin-dot();
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-animation: e-signRotate 1.2s infinite linear;
  animation: e-signRotate 1.2s infinite linear;
}

.e-sign-spin-lg .e-sign-spin-dot {
  font-size: 22px;
}

.e-sign-spin-lg .e-sign-spin-dot i {
  width: 10px;
  height: 10px;
}

@-webkit-keyframes e-signSpinMove {
  to {
    opacity: 1;
  }
}

@keyframes e-signSpinMove {
  to {
    opacity: 1;
  }
}

@-webkit-keyframes e-signRotate {
  to {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg);
  }
}

@keyframes e-signRotate {
  to {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg);
  }
}
