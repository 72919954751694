.container {
  padding: 20px;
  .dialogTitle {
    font-size: 16px;
    padding: 0 10px 10px 0;
  }
  .orgConfigInfoContainer {
    height: 280px;
    display: grid;
    grid-template-columns: 186px 1fr;
    grid-template-rows: 100%;
    .orgIcon {
      background-image: url("../../../assets/images/org_icon.svg");
      background-size: 186px 186px;
      background-position: center;
      background-repeat: no-repeat;
    }
    .btnGroup {
      display: flex;
      justify-content: flex-end;
      .primaryBtn {
        margin-right: 20px;
      }
    }
    .content0 {
      display: inline-grid;
      grid-template-rows: 1fr 2fr;
      font-size: 16px;
      .title {
        font-size: 18px;
        font-weight: 700;
        margin-top: 20px;
        display: flex;
        align-items: flex-end;
      }
      .form {
        margin-top: 20px;
        display: grid;
        grid-template-rows: 1fr auto;
      }

    }
    .content1 {
      display: flex;
      flex-direction: column;
      .msg {
        flex: 1;
        position: relative;
        > div {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .applyFormTitle {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 20px;
      }
      .applyForm {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .applyFormItemLabel {
          font-size: 14px;
          margin-bottom: 10px;
        }
      }
    }
    .content2 {
      display: flex;
      flex-direction: column;
      .msg2 {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }
      .title2 {
        font-size: 18px;
        font-weight: 700;
        margin-top: 20px;
      }
    }
    .nameOptions {
      :global {
        .ant-select-item {
          padding: 5px 5px 0 5px;
        }
        .ant-select-dropdown {
          max-height: 160px;
          background-color: rebeccapurple;
        }

        .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
          background-color: white;
        }

        .rc-virtual-list-holder {
          max-height: 160px !important;
        }
      }

      padding: 0 0 5px 0;

      .item {
        border: solid 1px #eaeaea;
        border-radius: 2px;
        padding: 5px;
        margin: 0;
        background-color: #f9f9f9;

        &:hover {
          background-color: #FEF8FF;
          border-color: #D285E5;
        }

        .match {
          color: #A236B2;
        }

        .top {
          display: flex;
        }

        .elli {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-break: break-all;
        }

        .elli2 {
          white-space: unset;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          line-height: 24px;
        }

      }
    }
  }
}