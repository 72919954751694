//@import '../viewer/style/viewer.variable.scss';
body[class='phantom-container'] *:not([support-outline='true']) {
  outline: 0px solid rgba(255, 255, 255) !important;
}
.NetError{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 40vh);
  p{
    font-weight: 400;
    font-size: 22px;
    text-align: center;
    color: #7B7B7B;
  }
}