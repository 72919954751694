.vc-panel {
  z-index: 999999 !important;
}

.sign-cn-choose-sign-type-dialog {
  width: 640px;
  .choose-sign-type-header {
    display: flex;
    margin-bottom: 15px;
    font-size: 20px;
    text-align: center;
    >div:nth-child(1) {
      flex: 1;
      font-weight: 700;
    }
  }
  .choose-sign-type-container {
    background-color: #F9F9F9;
    border: 1px solid #EBEBEB;
    border-radius: 4px;
    padding: 20px 22px;
    display: flex;
    justify-content: center;
    .block {
      position: relative;
      border: 1px solid #d873da;
      border-radius: 10px;
      box-shadow: 0 4px 10px #00000012, 0 -4px 10px #00000012;
      padding: 0 10px 28px 10px;
      &:hover {
        background-color: #fffcff;
        color: #a236b2;
        cursor: pointer;
      }
    }
    .tip {
      position: absolute;
      bottom: 5px;
      left: 50%;
      transform: translateX(-50%);
    }
    .choose-sign-type-person, .choose-sign-type-org {
      width: 152px;
      height: 152px;
      background-repeat: no-repeat;
      background-position: right;
      background-size: contain;
      position: relative;
    }
    .choose-sign-type-org {
    }
    .spin-loading {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      z-index: 1;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      right: 0;
    }
    .person-icon {
      width: 85px;
      height: 79px;
      background-image: url("../../../assets/images/phantom/person.svg");
      &:hover {
        outline:#A236B2 solid thin;
        border-radius: 10px;
      }
    }
    .org-icon {
      width: 85px;
      height: 79px;
      background-image: url("../../../assets/images/phantom/org.svg");
      &:hover {
        outline:#A236B2 solid thin;
        border-radius: 10px;
      }
    }
  }
}

.eSignCN-iframe-container {
  iframe {
    border: 0;
    width: 100vw;
    height: 99vh;
  }
}