.loadTxt {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-color);
}
.main {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 355px;
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
  .title {
    margin-top: 15px;
    font-weight: 700;
    font-size: 20px;
  }
  .tips {
    margin: 25px 0 20px;
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    text-align: center;
  }
  Button {
    width: 60%
  }

}