.container {
  text-align: center;
  padding: 10px 40px 50px;
  margin: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer {
  bottom: 0;
  width: 100%;
  position: fixed;
  height: 50px;
  padding: 10px 16px 7px;
  text-align: right;
  background-color: white;
}

.skeleton {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 360px;
  margin: 40px auto 0;
}

.loadTxt {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 360px;
  margin: 20px auto 0;
  font-size: 20px;
  line-height: 20px;
  color: gray;
  .tips {
    font-size: 12px;
    margin-top: 10px;
  }

}

.errorTxt {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: orangered;
  height: 200px;
}

.title {
  font-size: 24px;
  line-height: 24px;
  margin: 10px 0 10px;
}

.summary {
  //line-height: 26px;
  display: flex;
  justify-content: center;

  .hint {
    display: inline-block;
    width: 26px;
    height: 26px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url('../../assets/images/authPlugin/hint.svg');
  }

  span {
    margin-top: 2px;
    line-height: 21px;
    text-align: left;
  }
}

.btns {
  display: flex;
  align-items: center;
  margin: 20px auto 0;

  .arrow {
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center center;
    width: 36px;
    height: 19px;
    background-size: 36px 19px;
    background-image: url('../../assets/images/authPlugin/arrow.svg');
  }

  .disabled {
    filter: grayscale(100%);
    opacity: 0.4;
  }
}

.btn {
  margin: 0 auto;
  width: 141px;
  height: 141px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  border: 1px solid #D873DA;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.07), 0px -4px 10px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    background-color: #FFFCFF;
    color: #A236B2;
  }

  .text {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    margin: 10px 0 0 0;
  }

  .icon {
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center center;
    width: 85px;
    height: 79px;
    background-size: 85px 79px;
  }

  .relogin {
    background-image: url('../../assets/images/authPlugin/relogin.svg');
  }

  .p-auth {
    background-image: url('../../assets/images/authPlugin/p-auth.svg');
  }

  .o-auth {
    background-image: url('../../assets/images/authPlugin/org.svg');
  }

  .mobile {
    background-image: url('../../assets/images/authPlugin/mobile.svg');
  }

  .email {
    background-image: url('../../assets/images/authPlugin/email.svg');
  }

  .error {
    width: 91px;
    height: 90px;
    background-size: 91px 90px;
    background-image: url('../../assets/images/authPlugin/error.svg');
  }

  .waiting {
    width: 85px;
    height: 79px;
    background-size: 85px 79px;
    background-image: url('../../assets/images/authPlugin/waiting2.svg');
  }



}

.result {
  display: flex;
  padding: 10px 0 0 20px;
  align-items: center;

  .icon {
    position: relative;
    width: 168px;
    height: 168px;
    border-radius: 84px;
    background-color: #FFF3FF;
    display: flex;
    align-items: center;
    justify-content: center;

    .img {
      display: inline-block;
      background-repeat: no-repeat;
      background-position: center center;
      width: 114px;
      height: 106px;
      background-size: 114px 106px;
    }

    .mobile {
      background-image: url('../../assets/images/authPlugin/mobile.svg');
    }
    .email {
      background-image: url('../../assets/images/authPlugin/email.svg');
    }
    .cert {
      background-image: url('../../assets/images/authPlugin/p-auth.svg');
    }
    .org {
      background-image: url('../../assets/images/authPlugin/org.svg');
    }

    .stat {
      left: 95px;
      top: 95px;
      position: absolute;
      width: 32px;
      height: 32px;
      z-index: 2;
      border-radius: 16px;
      background: #42C2F9;
      border: 2px solid #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center; /**/

      .img {
        position: relative;
        display: inline-block;
        background-repeat: no-repeat;
        background-position: center center;
        width: 19px;
        height: 18px;
        background-size: 19px 18px;

      }

      .ok {
        background-image: url('../../assets/images/authPlugin/ok.svg');
      }

      .plus {
        background-image: url('../../assets/images/authPlugin/plus.svg');
      }

      .waiting {
        background-image: url('../../assets/images/authPlugin/waiting.svg');
      }

      .error {
        background-image: url('../../assets/images/authPlugin/plus.svg');
        transform: rotate(45deg);
        margin: 0;
      }
    }

    .statOk {
      background: #57C302;
    }

    .statErr {
      background: #FF6711;
    }
  }

  .container {
    flex: 1;
    margin: 0 20px 0 40px;
    text-align: left;
    line-height: 20px;
    padding: 0;
    height: 80vh !important;
  }

  .title {
    font-weight: 700;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.85);
  }

  .tips {
    text-align: left;
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    margin: 0 0 10px;
  }

  .error {
    color: #CD0404;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    margin-top: 10px;
    width: 100%;
  }

}

.buttons {
  bottom: 0;
  width: 100%;
  position: fixed;
  height: 50px;
  padding: 10px 16px 7px;
  text-align: right;
  background-color: white;
}

.sendCode {
  color: #0D86F5;
  cursor: default;
}

.agree {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: 20px auto 0;
  padding: 0 30px;
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-weight: 700;
      font-size: 16px;
      color: #595959;
      line-height: 24px;
    }
  }

  .box {
    width: 100%;
    padding: 20px;
    background-color: #F8F8F8;
    border: 1px solid #E2E2E2;
    border-radius: 5px;
    line-height: 28px;
    margin: 15px 0 0;
    font-weight: 400;
    font-size: 14px;
    color: #595959;
  }
}

