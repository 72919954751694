.nameOptions {
  max-height: 130px;
  :global {
    .ant-select-item {
      padding: 5px 5px 0 5px;
    }

    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      background-color: white;
    }

    .rc-virtual-list-holder {
      max-height: 160px !important;
    }
  }

  padding: 0 0 5px 0;

  .item {
    border: solid 1px #eaeaea;
    border-radius: 2px;
    padding: 5px;
    margin: 0;
    background-color: #f9f9f9;

    &:hover {
      background-color: #FEF8FF;
      border-color: #D285E5;
    }

    .match {
      color: #A236B2;
    }

    .top {
      display: flex;
    }

    .elli {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: break-all;
    }

    .elli2 {
      white-space: unset;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      line-height: 24px;
    }

  }
}