.main {
  display: flex;
  align-items: center;
  margin: 0 auto;
  max-width: 535px;
  .left {
    flex: 1;
    .title {
      margin-bottom: 15px;
      font-weight: 700;
      font-size: 20px;
      color: rgba(0, 0, 0, 0.85);
    }
    .form {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .tips {
        margin: 10px 0 20px;
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        text-align: center;
      }
      .error {
        color: #CD0404;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        text-align: left;
        margin-top: 10px;
        width: 100%;
      }
    }
    .buttons {
      width: 100%;
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
      button:first-child {
        margin-right: 30px;
      }
    }
  }
}

.sendCode{
  color: #0D86F5;
  cursor: default;
}
