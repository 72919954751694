flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

body > .fv__ui-webpdf{
    display: none;
}
.wrapEsignTools{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .header{
        width: 100%;
        height: 50px;
        background-color: #A236B2;
        display: flex;
        align-items: center;
        padding:0 40px;
        .title{
            font-weight: 700;
            font-size: 15px;
            color: #ffffff;
            margin-left: 10px;
        }
    }
    .container{
        width: 980px;
        margin: 0 auto;
        padding:20px;
        display: flex;
        flex: 1;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        .containerHeader{
            width: 100%;
            display: flex;
            .openFile{
                margin:15px 0 20px;
                background-color: #FFF5FF;
                height: 36px;
                color:#A236B2;
                border:1px solid #A236B2;
                img{
                    margin-right: 5px;
                    width: 20px;
                }
            }
            .fileName{
                flex: 1;
                @include flexCenter();
                p{
                    margin-bottom: 0;
                    font-weight: 700;
                    font-size: 16px;
                }
            }

        }
        .validResult{
            width: 100%;
            border: 1px solid #52C419;
            border-radius: 5px;
            padding: 10px 20px;
            font-size: 16px;
            margin-bottom: 10px;
            p{
                margin-bottom: 0;
            }
            .successText{
                color: #66B43F;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 8px;
                .successIcon{
                    margin-right: 11px;
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    background: url(assets/success.svg) no-repeat;
                }
            }
            .validInfo{
                padding-left:35px
            }
            span{
                margin-right: 20px;
                color: #222222;
            }
        }

        .docBoard{
            display: flex;
            width: 100%;
            height: 68vh;
            // height: 608px;
            padding:25px;
            background: #FFFFFF;
            border: 1px solid #E0E0E0;
            box-sizing: border-box;
            border-radius: 5px;
            overflow: auto;
            margin-bottom: 50px;
            .bold{
                font-weight: 700;
            }
            .container {
                >span {
                    width: 100%;
                    height: 100%;
                    position: relative;
                }
                .content-wrapper {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    .primaryMsg, .secondMsg {
                        width: 100%;
                        text-align: center;
                        color: #868686;
                        line-height: 1.5;
                        font-size: 20px;
                    }
                    .secondMsg {
                        font-size: 14px;
                    }
                }
                .ant-upload.ant-upload-drag {
                    background-color: inherit;
                    border: none;
                }
                .ant-upload.ant-upload-drag {
                    cursor: default;
                }
                .beforeUploadBgImg {
                    width: 300px;
                    height: 300px;
                    margin: 0 auto;
                    background-image: url("assets/verifyToolBg.svg");
                    background-repeat: no-repeat;
                    background-position: top;
                    background-size: cover;
                }

            }
            .board_left{
                width: 374px;
                border-right: 1px solid #E6E6E6;
                display: flex;
                flex-direction: column;
                .NoData{
                    padding-top:200px;
                    width: 100%;
                    font-size: 16px;
                    color: #868686;
                    text-align: center;
                    line-height: 1;
                }
                .wrapList{
                    padding-right: 25px;
                    ul {
                        padding:0;
                        margin:0;
                        li{
                            height: 58px;
                            padding:10px 0 10px 25px;
                            list-style: none;
                            cursor:pointer ;
                            p{
                                .name{
                                    margin-right: 10px;
                                }
                                margin-bottom: 0;
                            }
                            &.active{
                                background-color: #FFF5FF;
                            }
                        }
                    }
                }
            }
            .board_right{
                flex: 1;
                padding-left:60px;
                .eSignCNJwtValidImg {
                    width: 300px;
                    height: 300px;
                    margin: 0 auto;
                    background-image: url("assets/jwtValid.svg");
                    background-repeat: no-repeat;
                    background-position: top;
                    background-size: cover;
                }
                .eSignCNIgnoreTime {
                    display: flex;
                    justify-content: center;
                    padding: 10px 0;
                }
                .ant-legacy-form-item{
                    margin-bottom: 0;
                }
                .validSaml{
                    background: #A236B2;
                    color: #ffffff;
                    width: 100%;
                    border-radius: 50px;
                    bottom: 0;
                    right: 0;
                    margin-bottom: 20px;
                    &::after {
                        display: none;
                    }
                    &[disabled]{
                        opacity: 0.5;
                    }
                }
            }
        }
    }
}

body:has(.wrapEsignTools) {
    .fv__ui-webpdf {
        display: none;
    }
}