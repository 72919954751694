.seal-usage-record {
  .ant-table-pagination.ant-pagination{
    float: left!important;
    margin-left: 20px;
  }
  .seal-usage-record-table {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 5px;
  }
  .search-form {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 5px;
    padding: 15px 28px 0 28px;
    .ant-form-item {
      margin-bottom: 12px;
    }
    .ant-btn.ant-btn-primary {
      background-color: #982E91;
      border-color: #982E91;
      color: #fff;
    }
    .ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active {
      border-color: #c998c5;
      background: #c998c5;
      text-shadow: none;
      box-shadow: none;
    }
  }
}
