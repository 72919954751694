@headerHeight: 56px;
@leftWidth: 240px;
@rightWidth: 300px;
@paginationHeight: 42px;





.main {
  display: flex;
  height: calc(100vh - @headerHeight);

  .left {
    display: flex;
    width: @leftWidth;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
    //scroll-behavior: smooth;
    .title {
      display: flex;
      justify-content: space-between;
      width: 100%;
      color: #595959;
      font-size: 14px;
      font-weight: 600;
      margin: 10px;
      height: 24px;
      line-height: 24px;
    }

    .thumbItem {
      text-align: center;
      margin-bottom: 10px;

      .thumb {
        display: inline-flex;
        padding: 10px;

        &:hover, &:focus {
          background-color: #FDF8FD;
          outline: 1px solid #F6DAF7;
        }

      }

      .focused {
        background-color: #FDF8FD;
        outline: 1px solid #F6DAF7;
      }

      .bitmap {
        backgroundColor: #ffffff;
        outline: solid #d5d5d5 1px;
        box-shadow: 0 3px 12px rgba(0, 0, 0, 0.2);
      }

      .page {
        font-weight: 400;
        font-size: 14px;
        color: #707070;
      }

      .curr {
        color: #A236B2;
      }
    }


  }

  .leftHidden {
    position: absolute;
    left: 10px;
    top: 69px;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.2);
    color: white;
    display: none;
  }

  .showLeft {
    margin-left: 0;
    transition: margin-left 0.1s;
    -moz-transition: margin-left 0.1s; /* Firefox 4 */
    -webkit-transition: margin-left 0.1s; /* Safari 和 Chrome */
    -o-transition: margin-left 0.1s; /* Opera */
  }

  .hideLeft {
    margin-left: -@leftWidth;
    transition: margin-left 0.1s;
    -moz-transition: margin-left 0.1s;
    -webkit-transition: margin-left 0.1s;
    -o-transition: margin-left 0.1s;
  }

  .showRight {
    margin-right: 0;
    transition: margin-right 0.1s;
    -moz-transition: margin-right 0.1s;
    -webkit-transition: margin-right 0.1s;
    -o-transition: margin-right 0.1s;
  }

  .hideRight {
    margin-right: -@rightWidth;
    transition: margin-right 0.1s;
    -moz-transition: margin-right 0.1s;
    -webkit-transition: margin-right 0.1s;
    -o-transition: margin-right 0.1s;
  }

  .center {
    display: flex;
    background-color: #f1f1f1;
    width: calc(100% - @leftWidth - @rightWidth);
    flex-direction: column;
    border: #f1f1f1 solid 1px;
    border-bottom: 0;

    .pdf {
      height: calc(100% - @paginationHeight);
      width: 100%;
      position: relative;

      .alert {
        display: flex;
        position: absolute;
        top: 0;
        left: 2px;
        right: 15px;
        justify-content: center;
        z-index: 20;

        :global {
          .ant-alert-content{
            flex: unset;
          }
          .ant-alert-close-icon {
            position: fixed;
            right: 30px;
          }
        }
      }

      .btn {
        position: absolute;
        top: 50%;
        z-index: 20;
        margin: -36px 0 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 74px;
        background: #FFFFFF;
        &:hover {
          color: #A236B2;
        }
      }

      .btnLeft {
        left: 0;
        border-radius: 0px 4px 4px 0px;
        box-shadow: 1px 1px 1px rgba(0, 0, 0 0.1), 1px -1px 1px rgba(0, 0, 0 0.1);
        &:hover {
          box-shadow: 1px 1px 1px rgba(248, 222, 255, 0.8), 1px -1px 1px rgba(248, 222, 255, 0.8);
        }
      }

      .btnRight {
        right: 15px;
        border-radius: 4px 0px 0px 4px;
        box-shadow: -1px 1px 1px rgba(0, 0, 0 0.1), -1px -1px 1px rgba(0, 0, 0 0.1);
        &:hover {
          box-shadow: -1px 1px 1px rgba(248, 222, 255, 0.8), -1px -1px 1px rgba(248, 222, 255, 0.8);
        }
      }
    }

    .pagination {
      height: @paginationHeight;

      display: flex;
      flex-direction: row;
      align-items: center;


      border-top: #f1f1f1 solid 1px;
      background: #FFFFFF;

      .pageCenter {
        display: flex;
        flex: 1;
        justify-content: center;

        .scaleDiv {
          position: relative;

          .scale {
            background-color: white;
            position: absolute;
            z-index: 20;
            top: 2px;
            bottom: 2px;
            left: 12px;
            width: 70px;
            height: calc(100% - 4px);
            display: flex;
            justify-content: center;
            align-items: center;
            pointer-events: none;
          }
        }

      }

      button {
        margin: 0 10px;
      }
    }
  }

  .right {
    width: @rightWidth;
    padding: 10px 0 10px 10px;
    overflow-y: auto;

    :global {
      .ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
        margin-right: 6px;
      }

      .ant-steps-item-finish .ant-steps-item-icon {
        border-color: #35a200;
        background-color: #f6fff2;
      }



      .ant-steps-item-container > .ant-steps-item-icon {
        border-color: rgba(0,0,0,.25);
        background-color: rgba(0,0,0,.25);
        border-radius: 12px;

        .ant-steps-icon {
          color: white;
        }
      }

      .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-icon {
          border-color: #A8C302;
          background-color: #A8C302;
          border-radius: 12px;
      }

      .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
        background-color: #35a200;
      }


      .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
        border-color: #ff8925;
        background-color: #ff8925;
        border-radius: 12px;
      }
    }

    .tips {
      font-size: 14px;
      font-weight: 400;
      color: #8C8C8C;
      margin-bottom: 20px;

      .title {
        font-size: 16px;
        font-weight: 700;
        color: #595959;
      }
    }

    .item {
      width: 232px;
      margin-bottom: 27px;
      user-select: none;

      .content {
        display: flex;
        margin-left: 10px;
        width: 222px;
        border-radius: 4px;
        background-color: #F1F1F1;
        border: solid 1px #F1F1F1;
        padding: 5px 10px;
        align-items: center;

        .head {
          flex: 1;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;

          .title {
            color: #222222;
          }

          .company {
            margin-top: 5px;
            color: #707070;
          }
        }

        .bade {
          line-height: 14px;
          padding: 0 3px;
          height: 14px;
          font-size: 12px;
          border-radius: 4px;
          background-color: rgb(168, 195, 2);
          color: white;
          float: right;
        }


        &:before {
          content: '';
          position: absolute;
          top: 10px;
          left: 0px;
          height: 0;
          width: 0;

          border-top: 5px solid transparent;
          border-left: 5px solid transparent;
          border-right: 5px solid #F1F1F1;
          border-bottom: 5px solid transparent;
        }
      }

      .fields {
        margin: -5px 0 0 10px;
        width: 222px;
        border-radius: 4px;
        background-color: #F1F1F1;
        border-bottom: solid 1px #F1F1F1;
        padding: 5px;

        font-size: 14px;
        font-weight: 400;
        color: #222222;

        .field {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 5px;
          line-height: 35px;
          background-color: white;

          .icon {
            font-size: 16px;
            color: rgba(194, 194, 194, 1);
          }

          .iconSelected {
            color: rgba(168, 195, 2, 1);
            font-size: 16px;
          }

          &:hover {
            background-color: rgba(241, 241, 241, 0.5);
          }
        }
      }

      .contentCurrent {
        background-color: #fff1e1;
        border-color: #fff1e1;

        &:before {
          border-right: 5px solid #fff1e1;
        }
      }

      .fieldCurrent {
        background-color: #fff1e1;
      }

    }
  }

}

.result {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 355px;
  margin: 100pt auto 0;

  .title {
    margin-top: 15px;
    font-weight: 700;
    font-size: 20px;
    color: #73AD45;
  }

  .tips {
    margin: 25px 0 20px;
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    text-align: center;
  }
}

