.CCContainer {
  min-height: 250px;
  :global {
    .ant-input-search-icon::before {
      border: 0;
    }
  }
  .error {
    color: #cd0404;
    font-size: 12px;
    margin-top: 5px;
  }
  .btn {
    color: white;
    border-color: rgba(210, 133, 229, .8);
    background-color:  #A236B2;

    &:hover {
      box-shadow: 0 -6px 6px rgba(248, 222, 255, .35), 0 4px 4px rgba(248, 222, 255, .35);
    }
  }
}
