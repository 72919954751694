.main {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 600px;
  margin: 50px auto 0;
  padding: 0 30px;
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-weight: 700;
      font-size: 16px;
      color: #595959;
      line-height: 24px;
    }
  }

  .box {
    width: 100%;
    padding: 20px;
    background-color: #F8F8F8;
    border: 1px solid #E2E2E2;
    border-radius: 5px;
    line-height: 28px;
    margin: 15px 0 30px;
    font-weight: 400;
    font-size: 14px;
    color: #595959;
  }

  .loadingContainer {
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .loading {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  .loadingBg{
    width: 128px;
    height: 128px;
    border-radius: 64px;
    background: #EDB4FB;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @-webkit-keyframes loadingCircle {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes loadingCircle {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }


  .loadingIcon {
    background-image: url(../../../../assets/images/loading2.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 64px;
    width: 64px;
    height: 64px;
    -webkit-animation: loadingCircle 1.6s infinite linear;
    animation: loadingCircle 1.6s infinite linear;
  }
  .errorIcon {
    background-image: url(../../../../assets/images/error.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 264px;
    height: 164px;
  }

  .loadingText, .hasErrorText {
    margin: 0;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 40px;
    text-align: center;
    color: #222222;
  }
}
