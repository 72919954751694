.applyDialog {
  margin-top: -10px;
  .admin-btn {
    width: 80px;
    height: 22px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .spin-loading {
    display: flex;
    justify-content: center;
    margin-top: 100px;
  }

  .btn-color {
    color: #A236B2;
  }

  .btn-hover:hover {
    border-bottom: 1px #A236B2 solid;
    box-sizing: content-box;
  }

  .stamp-apply-container {
    overflow: hidden;
    position: relative;
    user-select: none;

    .header {
      height: 24px;
      display: flex;
      margin-bottom: 24px;
      justify-content: space-between;

      .left {
        display: flex;
        justify-content: center;
        align-content: center;
        color: grey;
        .title {
          font-size: 16px;
          font-weight: 500;
          color: #000;
        }
        .msg {
          font-size: 12px;
        }
      }

      .stamp-apply__refresh-btn {
        margin-right: 5px;
        margin-bottom: 2px;
        cursor: pointer;

        &:hover {
          border-bottom: 1px #A236B2 solid;
        }
      }
    }

    .stamp-list-msg {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      background: #fff;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      color: gray;
    }

    .list {
      height: 422px;
      overflow-y: auto;
      // grid布局，实现九宫格布局
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;
        margin: 0;
        padding: 0;


      .item {
        position: relative;
        height: 182px;
        border: 1px #d9d9d9 solid;
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: center 40%;
        background-size: 120px 120px;
        margin: 0;

        &:nth-child(3n) {
          margin-right: 0;
        }

        .no-permission_wraper {
          opacity: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.27);
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;

          button {
            border-radius: 28px;
          }

          &:hover {
            opacity: 1;
          }
        }

        .stamp-tag {
          background-color: rgba(255, 168, 17, .1);
          color: #FFA811;

          position: absolute;
          z-index: 20;
          top: 0;
          left: 0;
          min-width: 64px;
          padding: 0 6px;
          height: 24px;
          text-align: center;
          line-height: 24px;
          font-size: 12px;
          font-weight: 400;
        }

        .stamp-tag-success {
          position: absolute;
          z-index: 20;
          top: 0;
          left: 0;
          min-width: 64px;
          padding: 0 6px;
          height: 24px;
          text-align: center;
          line-height: 24px;
          font-size: 12px;
          font-weight: 400;

          background-color: rgba(21, 173, 49, .1);
          color: #15ad31;
        }

        .stamp-alias {
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 80px;
          white-space: nowrap;
          position: absolute;
          bottom: 3px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

  }
}

.iframe {
  width: 100%;
  border: 0;
}

.loading {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff4d4f;
  font-size: 16px;

  svg {
    font-size: 20px;
  }
}
