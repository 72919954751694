.container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  user-select: none;
  position: absolute;
  transition: 0.5s all ease-in-out;

  .drawContainer {
    width: 100vw;
    height: calc(100vh - 3rem);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eeeeee;
    position: relative;

    .tips {
      color: #d1d1d1;
      font-size: 2.5rem
    }

    .canvas {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: transparent;
    }
  }

  .opts {
    width: 100vw;
    height: 3rem;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    padding: 0 0.8rem;
    justify-content: space-between;

    .btn {
      width: 80px;
      height: 28px;
      line-height: 0;
    }
  }
}

.result {
  text-align: center;
  .iconContainer {
    width: 9.25rem;
    height: 9.25rem;
    background-color: #FFF3FF;
    margin: 4rem auto 1rem;
    border-radius: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .iconFailed {
      width: 7.25rem;
      height: 6.75rem;
      background-image: url("../assets/qr-draw-fail.svg");
    }

    .iconSuccess {
      .iconFailed();
      background-image: url("../assets/qr-draw-ok.svg");
    }

    .statusUsed {
      width: 2rem;
      height: 2rem;
      border-radius: 2rem;
      background-color: #FFA234;
      background-image: url("../assets/ok.svg");
      background-size: 1.125rem 0.875rem;
      background-repeat: no-repeat;
      background-position: center;
      border: solid 0.125rem #ffffff;
      position: absolute;
      right: 1.8rem;
      bottom: 1.8rem;
    }
    .statusFailed {
      .statusUsed();
      background-color: #E34036;
      background-image: url("../assets/error.svg");
    }

    .statusSuccess {
      .statusUsed();
      background-color: #4aba09;
      background-image: url("../assets/ok.svg");
    }
  }
}
